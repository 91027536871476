import { FormattedMessage } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Nullable } from '../../types';

type NumericRangePriceMessageProps = {
    readonly rangeValues: Array<Nullable<number>>;
    readonly isInternalPriceDefaultMax: boolean;
};

const NumericRangePriceMessage = ({
    isInternalPriceDefaultMax,
    rangeValues,
}: NumericRangePriceMessageProps): JSX.Element => {
    const { tenantConfig } = useFragmentContext<FragmentContext>();

    return (
        <>
            <FormattedMessage
                defaultMessage="{min} {currencySymbol} - {max} {currencySymbol}"
                values={{
                    min: rangeValues[0],
                    max: rangeValues[1],
                    currencySymbol: tenantConfig.currency.symbol,
                }}
            />
            {isInternalPriceDefaultMax && (
                <span>
                    <FormattedMessage defaultMessage="+" />
                </span>
            )}
        </>
    );
};

export { NumericRangePriceMessage };
