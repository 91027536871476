import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { FilterDropdownBase, NumericRange } from '@jsmdg/yoshi';
import { type Filter as FilterType } from '../../../../../shared/types/search';
import { usePriceFilter } from '../../../../hooks';
import { type SearchReducerActionType } from '../../../../reducers/searchReducer';
import { type Nullable } from '../../../../types';
import { FloatingTogglePriceMessage, NumericRangePriceMessage } from '../../../PriceMessage';
import styles from './PriceFilter.module.scss';

const messages = defineMessages({
    dropdownLabel: {
        defaultMessage: 'Alle Preise',
    },
    submitLabel: {
        defaultMessage: 'Anwenden',
    },
    resetLabel: {
        defaultMessage: 'Zurücksetzen',
    },
});

const PRICE_FILTER_STEP = 10;

type PriceFilterProps = {
    readonly currencyCode: string;
    readonly onSubmit: (
        label: SearchReducerActionType,
        value: { min: Nullable<number>; max: Nullable<number> } | Nullable<number>,
    ) => void;
    readonly options: { min: number; max: number };
    readonly values: Array<Nullable<number>>;
    readonly shouldReset?: boolean;
    readonly filter: FilterType;
};

const PriceFilter = ({
    currencyCode,
    filter,
    onSubmit,
    options,
    shouldReset = false,
    values,
}: PriceFilterProps): JSX.Element => {
    const {
        filterRef,
        handleOnClickOutside,
        handleReset,
        internalData,
        onPriceChange,
        onRangeChange,
        priceData,
        rangeValues,
        sliderDidUpdate,
    } = usePriceFilter({
        currencyCode,
        onSubmit,
        options,
        shouldReset,
        values,
        filter,
    });

    const intl = useIntl();

    const getPriceMessage = (): JSX.Element | string => {
        if (!priceData.isDefaultMin || !priceData.isDefaultMax) {
            return <FloatingTogglePriceMessage priceData={priceData} />;
        }

        return intl.formatMessage(messages.dropdownLabel);
    };

    return (
        <div ref={filterRef} data-testid="experience-finder-price-filter">
            <FilterDropdownBase
                label={getPriceMessage()}
                resetLabel={intl.formatMessage(messages.resetLabel)}
                submitLabel={intl.formatMessage(messages.submitLabel)}
                numSelected={!priceData.price[0] && !priceData.price[1] ? 0 : 1}
                onSubmit={() => {
                    onPriceChange(rangeValues);
                }}
                onReset={handleReset}
                onClickOutside={handleOnClickOutside}
                hasChanged={sliderDidUpdate}
                dataTestId="product-price-button"
                className={classNames(styles.priceFloatingToggle, 'mb-1-5x mb-md-0')}
            >
                <div className="px-2x px-sm-1x">
                    <NumericRange
                        values={rangeValues}
                        onChange={((val: number[]) => onRangeChange(val)) as () => void}
                        options={[options.min, options.max]}
                        step={PRICE_FILTER_STEP}
                        pushable={PRICE_FILTER_STEP}
                        displayValue={
                            <NumericRangePriceMessage
                                rangeValues={rangeValues}
                                isInternalPriceDefaultMax={internalData.isDefaultMax}
                            />
                        }
                    />
                </div>
            </FilterDropdownBase>
        </div>
    );
};

export { PriceFilter };
