import classNames from 'classnames';

import { LiveLocationIcon, PureButton } from '@jsmdg/yoshi';
import { storageAvailable } from '../../../../../helper/storageHelper';
import { SearchReducerActionType } from '../../../../../reducers/searchReducer';
import styles from './CurrentLocationButton.module.scss';

type CurrentLocationButtonProps = {
    readonly onSubmit: (type: SearchReducerActionType) => void;
    readonly location?: string;
};

const shouldShowLocationButton = (location: string): boolean => {
    if (location) return false;
    if (typeof window !== 'undefined' && storageAvailable('sessionStorage')) {
        return window.sessionStorage.getItem('locationApiAccepted') !== 'false';
    }

    return true;
};

const CurrentLocationButton = ({
    location = '',
    onSubmit,
}: CurrentLocationButtonProps): JSX.Element | null => {
    const getCurrentLocation = (): void => {
        onSubmit(SearchReducerActionType.GeoCoordinates);
    };

    const showLocationButton = shouldShowLocationButton(location);

    if (!showLocationButton) {
        return null;
    }

    return (
        <PureButton
            onClick={getCurrentLocation}
            className={classNames(
                'd-none d-xs-flex align-items-center justify-content-center pl-0-5x',
                styles.button,
            )}
        >
            <LiveLocationIcon className={styles.icon} />
        </PureButton>
    );
};

export { CurrentLocationButton };
