import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Price } from '../../shared/types/searchResponse';
import styles from '../components/common/general_styles.module.scss';

export const usePriceClassName = (
    originalPrice?: Price | string,
    variation?: Record<string, string>,
): string | undefined => {
    if (!originalPrice) return undefined;

    const { isMydays } = useFragmentContext<FragmentContext>();
    let { featureFlagsVariation } = useFragmentContext<FragmentContext>();
    if (variation) featureFlagsVariation = variation;

    if (
        !featureFlagsVariation?.strike_price_main ||
        featureFlagsVariation?.strike_price_main === 'default' ||
        isMydays
    )
        return undefined;

    return featureFlagsVariation?.strike_price_main === 'red'
        ? styles.redStrikePrice
        : styles.blueStrikePrice;
};
