import { useMemo } from 'react';
import { type Middleware } from 'instantsearch.js';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { useInsightsClient } from './useInsightsClient';

export const useInsightsMiddleware = (): Middleware | undefined => {
    const {
        algoliaConfig: { appId, searchKey, userToken },
    } = useFragmentContext<FragmentContext>();
    const { insightsClient } = useInsightsClient();

    return useMemo(() => {
        if (insightsClient && userToken) {
            const middleware = createInsightsMiddleware({
                insightsClient,
            });

            insightsClient('init', {
                appId,
                apiKey: searchKey,
            });
            insightsClient('setUserToken', userToken);

            return middleware;
        }

        return undefined;
    }, [appId, insightsClient, searchKey, userToken]);
};
