import classNames from 'classnames';

import { BreakpointsPx, getImagesByDimension } from '@jsmdg/imgix';
import { Image, type SelectOption } from '@jsmdg/yoshi';
import { ExperienceFinder } from './ExperienceFinder';
import desktopBgImage from './images/background_desktop.jpg';
import mobileBgImage from './images/background_mobile.jpg';
import styles from './HeroStage.module.scss';

const mobileImage = getImagesByDimension(mobileBgImage, {
    [BreakpointsPx.xs]: { width: 435, height: 364 },
    [BreakpointsPx.xl]: { width: 800, height: 400 },
});

const desktopImage = getImagesByDimension(desktopBgImage, {
    [BreakpointsPx.xs]: { width: 800, height: 400 },
    [BreakpointsPx.xl]: { width: 1_200, height: 490 },
});

type HeroStageProps = {
    readonly categories: SelectOption[];
};

const HeroStage = ({ categories }: HeroStageProps) => {
    return (
        <div
            className={classNames(
                styles.heroWrapper,
                'position-relative d-flex justify-content-center mb-5x mb-sm-0',
            )}
        >
            <Image
                alt="Hero stage desktop"
                className={classNames(styles.image, 'd-none d-sm-block w-100')}
                src={desktopImage.src}
                srcSet={desktopImage.srcset}
                sizes={desktopImage.sizes}
                lazyLoad={false}
            />

            <Image
                alt="Hero stage mobile"
                className={classNames(styles.image, 'd-sm-none w-100')}
                src={mobileImage.src}
                srcSet={mobileImage.srcset}
                sizes={mobileImage.sizes}
                lazyLoad={false}
            />

            <div className={classNames(styles.curvedOverlay)} />
            <div className={classNames(styles.overlay, 'position-absolute px-4x w-100')}>
                <ExperienceFinder categories={categories} />
            </div>
        </div>
    );
};

export { HeroStage };
