import { type ChangeEvent, useState } from 'react';

import { GA4EventName, trackFilterInteraction } from '@jsmdg/tracking';
import { Select, type SelectOption } from '@jsmdg/yoshi';
import { type Filter } from '../../../shared/types/search';
import { getActiveFilters } from '../../hooks/getActiveFilters';
import styles from './ExperienceFinder.module.scss';

type CategoriesSelectProps = {
    readonly onSelect: (path: string, label: string) => void;
    readonly options: SelectOption[];
    readonly initialCategory: SelectOption;
    readonly filter: Filter;
};

const CategoriesSelect = ({
    filter,
    initialCategory,
    onSelect,
    options,
}: CategoriesSelectProps): JSX.Element => {
    const [selectedValue, setSelectedValue] = useState(initialCategory.value);

    const handleSelect = async ({ target }: ChangeEvent<HTMLSelectElement>): Promise<void> => {
        const { selectedIndex, value } = target;
        const selectedOptionLabel = selectedIndex !== -1 ? target.options[selectedIndex].label : '';
        onSelect(value, selectedOptionLabel);
        setSelectedValue(value);
        await window.yieldToMainThread();
        if (selectedIndex !== -1) {
            const activeFiltersString = getActiveFilters(filter, ['Category']);
            const GA4Data = {
                eventName: GA4EventName.SetFilter,
                filter_type: 'Category',
                filter_value: selectedOptionLabel,
            };

            trackFilterInteraction(
                'SetCategory',
                selectedOptionLabel,
                GA4Data,
                activeFiltersString,
            );
        }
    };

    return (
        <div className={styles.categoriesSelect} data-testid="experience-finder-categories-select">
            <Select
                className={styles.categoriesSelectToggle}
                onChange={handleSelect}
                options={options}
                value={selectedValue}
            />
        </div>
    );
};

export { CategoriesSelect };
