import { type Dispatch, useEffect, useReducer, useState } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4FilterListType } from '@jsmdg/tracking';
import { Breakpoint, GridView, useBreakpoint } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Search } from '../../shared/types/search';
import { getGeoPosition } from '../helper/getGeoPosition';
import { storageAvailable } from '../helper/storageHelper';
import {
    searchReducer,
    type SearchReducerAction,
    SearchReducerActionType,
    type SearchReducerState,
} from '../reducers/searchReducer';
import { getActiveFilters } from './getActiveFilters';
import { trackLocateMeClicked } from './locationFilter.tracking';

type UseSearchStateReturn = {
    searchState: SearchReducerState;
    dispatchSearch: Dispatch<SearchReducerAction>;
    geoLocationError: GeolocationPositionError | undefined;
};

const useSearchState = (
    gridView: GridView,
    isModalView?: boolean,
    search: Search = {},
): UseSearchStateReturn => {
    const [searchState, dispatchSearch] = useReducer(searchReducer, search);
    const [geolocationError, setGeolocationError] = useState<GeolocationPositionError>();
    const { reverseGeocode } = useFragmentContext<FragmentContext>();
    const isDesktop = useBreakpoint(Breakpoint.SM);

    useEffect(() => {
        if (searchState.type === SearchReducerActionType.ClearError) {
            setGeolocationError(undefined);
            return;
        }

        if (searchState.type === SearchReducerActionType.GeoCoordinates) {
            (async () => {
                let geoPosition;

                const getListType = (): GA4FilterListType => {
                    if (isDesktop) {
                        return gridView === GridView.ListView
                            ? GA4FilterListType.ListView
                            : GA4FilterListType.TileView;
                    }

                    return gridView === GridView.ListView
                        ? GA4FilterListType.ListViewMenu
                        : GA4FilterListType.TileViewMenu;
                };

                try {
                    geoPosition = await getGeoPosition();
                } catch (error) {
                    const geoLocationPositionError = error as GeolocationPositionError;
                    if (
                        storageAvailable('sessionStorage') &&
                        window.GeolocationPositionError &&
                        geoLocationPositionError.code ===
                            window.GeolocationPositionError.PERMISSION_DENIED
                    ) {
                        window.sessionStorage.setItem('locationApiAccepted', 'false');
                    }

                    setGeolocationError(geoLocationPositionError);

                    return;
                }

                if (storageAvailable('sessionStorage')) {
                    window.sessionStorage.setItem('locationApiAccepted', 'true');
                }

                let locationName;
                try {
                    const geocodeResponse = await reverseGeocode(
                        geoPosition.coords.latitude,
                        geoPosition.coords.longitude,
                    );
                    locationName = geocodeResponse.results[0].formatted_address;
                } catch {
                    locationName = 'no_location_name';
                }

                const activeFiltersString = getActiveFilters(search.filter, [
                    'Location',
                    'distance',
                ]);

                await trackLocateMeClicked(
                    'LocateMe',
                    locationName,
                    activeFiltersString,
                    isModalView ? GA4FilterListType.MapView : getListType(),
                );

                dispatchSearch({
                    type: SearchReducerActionType.Location,
                    value: {
                        lat: geoPosition.coords.latitude,
                        long: geoPosition.coords.longitude,
                        name: locationName,
                        distance: 100,
                    },
                });
            })();
        }
    }, [searchState, reverseGeocode, search.filter, isDesktop, gridView, isModalView]);

    return { searchState, geoLocationError: geolocationError, dispatchSearch };
};

export { useSearchState };
