export const isStringArray = (value: unknown): value is string[] =>
    Array.isArray(value) && value.every(item => typeof item === 'string');

export function shuffle<TData>(array: TData[]): TData[] {
    const shuffledArray = Array.from(array);

    // eslint-disable-next-line no-plusplus
    for (let c = shuffledArray.length - 1; c > 0; c--) {
        const b = Math.floor(Math.random() * (c + 1));
        [shuffledArray[c], shuffledArray[b]] = [shuffledArray[b], shuffledArray[c]];
    }

    return shuffledArray;
}
