import { type Context, createContext } from 'react';
import { type SearchResults } from 'algoliasearch-helper';

import { SearchStatus } from '../enums/searchStatus';
import { type AlgoliaSearchContext } from '../types/algoliaSearchContext';

const createAlgoliaContext = (): Context<AlgoliaSearchContext> => {
    return createContext<AlgoliaSearchContext>({
        status: SearchStatus.Idle,
        getResults<T>(): SearchResults<T> | undefined {
            return undefined;
        },
    });
};

const createAlgoliaSearchContext = createAlgoliaContext();

export { createAlgoliaSearchContext };
