import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
    Breakpoint,
    Button,
    ButtonColor,
    ButtonShape,
    Image,
    PureButton,
    Skeleton,
    SkeletonAnimation,
    useBreakpoint,
    useIntersection,
    WaypointIcon,
} from '@jsmdg/yoshi';
import { eventTypes } from '../../types';
import { trackOpenMapView } from '../MapView/mapViewTracking';
import map from './map.jpg';
import mapTablet from './map-tablet.jpg';
import styles from './MapContainer.module.scss';

const openMap = (clickElement: string, clickText: string): void => {
    document.dispatchEvent(new CustomEvent(eventTypes.SHOW_MAP_VIEW));
    trackOpenMapView(clickElement, clickText);
};

const MapContainer = (): JSX.Element => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const isTablet = useBreakpoint(Breakpoint.XS);

    const [isIntersect, setTargetElement] = useIntersection({
        threshold: 0,
    });

    return (
        <div
            className={classNames(styles.container, 'position-relative my-3x my-sm-0')}
            ref={setTargetElement as (el: HTMLElement | null) => void}
        >
            {!isImageLoaded && (
                <Skeleton
                    className="position-absolute top-0 start-0 w-100 h-100"
                    animation={SkeletonAnimation.Wave}
                />
            )}

            <PureButton className="w-100 h-100" onClick={() => openMap('banner', 'Kartenansicht')}>
                <Image
                    src={isTablet ? mapTablet : map}
                    alt="Map container"
                    className={classNames(styles.image, 'w-100 h-100')}
                    onLoad={() => setIsImageLoaded(true)}
                />
            </PureButton>

            <Button
                color={ButtonColor.BrandDark}
                shape={ButtonShape.Pill}
                iconLeft={<WaypointIcon />}
                className={classNames(styles.button, 'position-absolute')}
                onClick={() => openMap('banner', 'Kartenansicht')}
            >
                <FormattedMessage defaultMessage="Kartenansicht" />
            </Button>

            {isImageLoaded && !isIntersect && (
                <Button
                    color={ButtonColor.BrandDark}
                    shape={ButtonShape.Pill}
                    iconLeft={<WaypointIcon />}
                    className={classNames(styles.mapButton, 'position-fixed bottom-0 start-50')}
                    onClick={() => openMap('floating button', 'Karte')}
                >
                    <FormattedMessage defaultMessage="Karte" />
                </Button>
            )}
        </div>
    );
};

export { MapContainer };
