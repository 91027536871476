import { type MouseEvent } from 'react';
import { type IntlShape } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    GA4EventName,
    GA4FeatureCategory,
    GA4ItemListType,
    type Product as CartProduct,
    ProductType as TrackingProductType,
    trackProductTileServiceInteraction,
} from '@jsmdg/tracking';
import { DiscountBadgeShape } from '@jsmdg/yoshi';
import { pageConfig, PageType } from '../../shared/enums/pageType';
import { ProductType } from '../../shared/enums/productType';
import { type ContentBlock } from '../../shared/types/contentBlock';
import { type DiscountBadge } from '../../shared/types/discountBadge';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Image, type Price, type Product } from '../../shared/types/searchResponse';
import { type TrackingListName } from '../enums/trackingListName';
import { enhanceUrlWithSearchParams } from '../helper/enhanceUrlWithSearchParams';
import { type ProductTileMessagesType } from '../helper/getProductTileMessages';

const trackingProductTypeMap: Record<ProductType, TrackingProductType> = {
    [ProductType.ExperienceProduct]: TrackingProductType.Experience,
    [ProductType.BoxProduct]: TrackingProductType.Box,
    [ProductType.ValueVoucherProduct]: TrackingProductType.ValueVoucher,
};

type UseProductTileDataReturnType = {
    locale: string;
    images: Image[];
    services: ContentBlock;
    url: string;
    hasClosestLocation: boolean;
    price: Price;
    originalPrice?: Price;
    discountBadge?: DiscountBadge;
    pageType: PageType;
    productTrackingData: CartProduct;
    onToggleServices: (showServices: boolean) => Promise<void>;
    onCloseServices: () => Promise<void>;
    onServicesActionButtonClick: (event?: MouseEvent) => Promise<void>;
    getLocationLabel: () => string | undefined;
    getParticipantsLabel: () => string;
};

const useProductTileData = (
    product: Product,
    intl: IntlShape,
    listInfo: {
        isListView?: boolean;
        indexName?: string;
        queryId?: string;
        trackingListName?: TrackingListName;
        hasLocationFilter?: boolean;
        isModalView?: boolean;
        isOverlay?: boolean;
    },
    messages?: ProductTileMessagesType,
): UseProductTileDataReturnType => {
    const { isListView, hasLocationFilter, isModalView, isOverlay } = listInfo;

    const {
        locale,
        type: pageType,
        tenantConfig,
        isMydays,
    } = useFragmentContext<FragmentContext>();

    let listType = GA4ItemListType.ListView;

    if (isModalView) {
        listType = isOverlay ? GA4ItemListType.MapViewOverlay : GA4ItemListType.MapViewList;
    } else if (!isListView) {
        listType = GA4ItemListType.TileView;
    }

    const {
        productId,
        title,
        manufacturerSku,
        images,
        productType,
        trackingName,
        trackingPath,
        rating,
        participants,
        locations,
        classifications,
        closestLocation,
        services,
    } = product;

    const url = pageType === PageType.Redemption ? `/einloesen/${product.productId}` : product.url;

    const { showDiscount } = pageConfig[pageType] ?? { showDiscount: false };

    const price = showDiscount ? product.price : product.originalPrice || product.price;
    const originalPrice = showDiscount ? product.originalPrice : undefined;

    const discountBadge =
        showDiscount && product.discountBadge
            ? {
                  ...product.discountBadge,
                  withHint: isMydays,
                  className: isMydays ? 'ml-0-5x mt-0-5x ml-xs-1x mt-xs-1x' : '',
                  shape: isMydays ? DiscountBadgeShape.Circle : DiscountBadgeShape.Rectangle,
              }
            : undefined;

    const productTrackingData: CartProduct = {
        id: productId,
        trackingName,
        url,
        productType: trackingProductTypeMap[productType],
        title,
        trackingPath,
        manufacturerSku,
        price: {
            gross: {
                amount: Number(price.gross.amount),
                currencyCode: price.gross.currencyCode,
            },
        },
        originalPrice: originalPrice && {
            gross: {
                amount: Number(originalPrice.gross.amount),
                currencyCode: originalPrice.gross.currencyCode,
            },
        },
        discountBadgeId: discountBadge?.id,
        rating,
        locationCount: locations?.length,
    };

    if (participants?.min && participants?.max) {
        productTrackingData.participants = {
            min: participants.min,
            max: participants.max,
        };
    }

    if (hasLocationFilter) {
        productTrackingData.closestLocationDistance = closestLocation?.distance.value;
        productTrackingData.locationCount = undefined;
    }

    const isOnlineExperience =
        classifications?.includes('LocationOnline') &&
        locations?.some(location =>
            tenantConfig?.onlineExperienceOptions.includes(location.name as string),
        );

    const hasClosestLocation = !!closestLocation && !isOnlineExperience;

    const onToggleServices = async (showServices: boolean): Promise<void> => {
        await window.yieldToMainThread();
        trackProductTileServiceInteraction(
            'ProductTile',
            showServices ? 'Collapse' : 'Expand',
            'Services',
            {
                eventName: GA4EventName.ClickTextLink,
                feature_category: GA4FeatureCategory.ProductList,
                click_element: showServices ? 'Producttile close' : 'Producttile expand',
                click_text: 'Details',
                list_type: listType,
                product_title: product.title,
            },
        );
    };

    const onCloseServices = async (): Promise<void> => {
        await window.yieldToMainThread();
        trackProductTileServiceInteraction('ProductTile', 'Close', 'Services', {
            eventName: GA4EventName.ClickTextLink,
            feature_category: GA4FeatureCategory.ProductList,
            click_element: 'Producttile close',
            click_text: 'X',
            list_type: listType,
            product_title: product.title,
        });
    };

    const onServicesActionButtonClick = async (event?: MouseEvent): Promise<void> => {
        event?.preventDefault();
        window.location.href = enhanceUrlWithSearchParams(url);
        await window.yieldToMainThread();
        trackProductTileServiceInteraction('ProductTile', 'ButtonClick', 'More information', {
            eventName: GA4EventName.ClickButton,
            feature_category: GA4FeatureCategory.ProductList,
            click_element: 'Producttile more information',
            click_text: 'Mehr erfahren',
            click_url: window.location.origin + url,
            list_type: listType,
            product_title: product.title,
        });
    };

    const getLocationLabel = (): string | undefined => {
        if (!locations?.length || !messages) {
            return undefined;
        }

        if (hasClosestLocation) {
            return closestLocation.name;
        }

        if (isOnlineExperience) {
            return intl.formatMessage(messages.onlineExperienceLocation);
        }

        if (locations.length > 1) {
            return intl.formatMessage(messages.multipleLocations, {
                locationCount: locations.length,
            });
        }

        return locations[0].name;
    };

    const getParticipantsLabel = (): string => {
        if (!participants || !messages) {
            return '';
        }

        const { min, max } = participants;

        return intl.formatMessage(messages.participantsLabel, {
            participants: min === max ? max : `${min as number}-${max as number}`,
            maxCount: max,
        });
    };

    return {
        locale,
        images,
        services,
        url,
        hasClosestLocation,
        price,
        originalPrice,
        discountBadge,
        pageType,
        productTrackingData,
        onToggleServices,
        onCloseServices,
        onServicesActionButtonClick,
        getLocationLabel,
        getParticipantsLabel,
    };
};

export { useProductTileData };
