import { useEffect, useReducer, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FilterGA4Data, GA4EventName, trackFilterInteraction } from '@jsmdg/tracking';
import {
    Breakpoint,
    Button,
    ButtonColor,
    CurvedArrowIcon,
    RenderType,
    SearchIcon,
    type SelectOption,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../../shared/types/fragmentContext';
import { PriceFilterDefault } from '../../../enums/priceFilterDefault';
import { formatTrackingPriceRange } from '../../../helper/formatPrice';
import { getGeoPosition } from '../../../helper/getGeoPosition';
import { toUrlSearchParameters } from '../../../helper/mapUrlParameters';
import { storageAvailable } from '../../../helper/storageHelper';
import { getActiveFilters } from '../../../hooks/getActiveFilters';
import { searchReducer, SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { CategoriesSelect } from './Filters/CategoriesSelect';
import { LocationFilter } from './Filters/Location';
import { PriceFilter } from './Filters/Price';
import styles from './ExperienceFinder.module.scss';

const messages = defineMessages({
    headline: { defaultMessage: 'Finde jetzt dein passendes Erlebnis' },
    cta: { defaultMessage: 'Erlebnis finden' },
});
const PRICE_BOUNDARY_MIN = PriceFilterDefault.Min;
const PRICE_BOUNDARY_MAX = PriceFilterDefault.Max;

type ExperienceFinderProps = {
    readonly categories: SelectOption[];
};

const ExperienceFinder = ({ categories }: ExperienceFinderProps): JSX.Element => {
    const intl = useIntl();
    const { locale, reverseGeocode, tenantConfig } = useFragmentContext<FragmentContext>();
    const { currency } = tenantConfig;
    const isDesktop = useBreakpoint(Breakpoint.SM);
    const [category, setCategory] = useState<SelectOption>({
        label: tenantConfig.allExperiencesLabel,
        value: tenantConfig.allExperiencesPath,
    });
    const [geoLocationError, setGeoLocationError] = useState<GeolocationPositionError>();
    const [{ filter = {}, type: searchStateType }, dispatchSearch] = useReducer(searchReducer, {});
    const filterQuery = `?${toUrlSearchParameters({ filter })}`;

    const handleFilterChange = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
    ): void => {
        dispatchSearch({ type, value });
    };

    const updatePathAndLabel = (selection: SelectOption): void => {
        setCategory(selection);
    };

    const getCtaTrackingValue = (): string => {
        const location = filter.location
            ? `${filter.location.name || ''}_${filter.location.distance}km`
            : 'not selected';
        const { max = PRICE_BOUNDARY_MAX, min = PRICE_BOUNDARY_MIN } = filter?.price || {};
        const priceRange = formatTrackingPriceRange({
            locale,
            currencyCode: currency.code,
            min,
            max,
        });
        return `${location}_${category.label}_${priceRange}`;
    };

    const trackCtaClick = async (): Promise<void> => {
        await window.yieldToMainThread();

        const ctaGA4Data: FilterGA4Data = {
            eventName: GA4EventName.ClickButton,
            click_element: 'hero stage experience finder CTA',
            click_text: isDesktop ? 'SearchIcon' : intl.formatMessage(messages.cta),
        };

        const activeFilterString =
            category.label === tenantConfig.allExperiencesLabel
                ? getActiveFilters(filter)
                : getActiveFilters(filter, ['Category']);

        trackFilterInteraction('SetFilter', getCtaTrackingValue(), ctaGA4Data, activeFilterString);
    };

    useEffect(() => {
        if (searchStateType === SearchReducerActionType.GeoCoordinates) {
            (async () => {
                let geoPosition;
                try {
                    geoPosition = await getGeoPosition();
                } catch (error) {
                    const locationPositionError = error as GeolocationPositionError;
                    if (
                        storageAvailable('sessionStorage') &&
                        window.GeolocationPositionError &&
                        locationPositionError.code ===
                            window.GeolocationPositionError.PERMISSION_DENIED
                    ) {
                        window.sessionStorage.setItem('locationApiAccepted', 'false');
                    }

                    setGeoLocationError(locationPositionError);

                    return;
                }

                if (storageAvailable('sessionStorage')) {
                    window.sessionStorage.setItem('locationApiAccepted', 'true');
                }

                let locationName;
                try {
                    const geocodeResponse = await reverseGeocode(
                        geoPosition.coords.latitude,
                        geoPosition.coords.longitude,
                    );
                    locationName = geocodeResponse.results[0].formatted_address;
                } catch {
                    locationName = 'no_location_name';
                }

                dispatchSearch({
                    type: SearchReducerActionType.Location,
                    value: {
                        lat: geoPosition.coords.latitude,
                        long: geoPosition.coords.longitude,
                        name: locationName,
                        distance: 100,
                    },
                });
            })();
        }
    }, [reverseGeocode, searchStateType]);

    return (
        <>
            <div className="d-flex justify-content-start justify-content-xs-center">
                <h2 className={classNames(styles.headline, 'fw-bold mb-1x')}>
                    {intl.formatMessage(messages.headline)}
                </h2>
                <CurvedArrowIcon
                    size={37}
                    className={classNames(styles.curvedArrow, 'mt-4x mt-xs-2-5x')}
                />
            </div>
            <div className={classNames(styles.experienceFinder, 'grid gap-1x p-1-5x p-xs-3x')}>
                <div className="g-col-12 g-col-sm-12 g-col-md-11">
                    <div className="grid gap-1x">
                        <div className="g-col-12 g-col-sm-4 mb-0-5x mb-sm-0">
                            <LocationFilter
                                locationName={filter.location?.name}
                                onSubmit={handleFilterChange}
                                err={geoLocationError}
                                filter={filter}
                            />
                        </div>

                        <div className="g-col-12 g-col-sm-4 mb-0-5x mb-sm-0">
                            <CategoriesSelect
                                categories={categories}
                                onSelect={updatePathAndLabel}
                                filter={filter}
                            />
                        </div>

                        <div className="g-col-12 g-col-sm-4 mb-0-5x mb-sm-0">
                            <PriceFilter
                                values={[null, null]}
                                onSubmit={handleFilterChange}
                                currencyCode={currency.code}
                                filter={filter}
                            />
                        </div>
                    </div>
                </div>

                <div className="g-col-12 g-col-md-1">
                    <Button
                        href={`${category.value}${filterQuery}`}
                        className="p-xs-2x h-100 w-100"
                        onClick={trackCtaClick}
                        color={ButtonColor.Complementary}
                        as={RenderType.Link}
                    >
                        <SearchIcon size={28} className="d-none d-md-block" />
                        <span className="d-md-none">{intl.formatMessage(messages.cta)}</span>
                    </Button>
                </div>
            </div>
        </>
    );
};

export { ExperienceFinder };
