import { useEffect, useMemo, useState } from 'react';
import { Index } from 'react-instantsearch';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';
import { SearchParameters } from 'algoliasearch-helper';
import isMatch from 'lodash/isMatch';

import { getSettingStorage, ProductView, SettingStorageProperties } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventLabel, trackPLPViewChange, trackSearchResultPage } from '@jsmdg/tracking';
import { GridView, Notification } from '@jsmdg/yoshi';
import { NotificationVariant } from '@jsmdg/yoshi/dist/enums/notification';
import { ObjectType } from '../../../shared/enums/objectType';
import { PageType } from '../../../shared/enums/pageType';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { SortingField, SortingOrder } from '../../../shared/enums/sorting';
import { getAlgoliaIndex } from '../../../shared/helpers/getAlgoliaIndex';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Filter, type Search, type Sorting } from '../../../shared/types/search';
import { type AlgoliaProduct } from '../../../shared/types/searchResponse';
import { getAdjustedItemCountByLimit } from '../../helper/getAdjustedItemCountByLimit';
import { getGridView } from '../../helper/getGridView';
import { HoverProvider } from '../../helper/mapViewHoverProvider';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { useAlgoliaProductSearchState } from '../../hooks/useAlgoliaProductSearchState';
import { useSearchState } from '../../hooks/useSearchState';
import { eventTypes, type InitialPageFilterType } from '../../types';
import { ProductListContent } from './ProductListContent';
import { ProductListFilters } from './ProductListFilters';
import { ProductListSearchHeadline } from './ProductListSearchHeadline';

const LoadableMapViewModal = loadable(async () => import('../MapView/MapViewModal'));

type ProductListProps = {
    readonly indexId: string;
    readonly initialSearch?: Search;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly lazyLoad: boolean;
    readonly hasUiFilters?: boolean;
    readonly showLocationFilter?: boolean;
    readonly verticalPosition?: number;
    readonly pageType: PageType;
    readonly pageId?: string;
    readonly pageTitle?: string;
    readonly isLastList?: boolean;
    readonly noResultFallback?: JSX.Element;
    readonly trackingName?: string;
};

const messages = defineMessages({
    tileViewText: {
        defaultMessage: 'Kachel',
    },
    listViewText: {
        defaultMessage: 'Liste',
    },
    isFailedAlgoliaRequest: {
        defaultMessage:
            'Da wir gerade unser Produktportfolio überarbeiten, siehst du eine kategorieübergreifende Auswahl unserer Erlebnisse. Schaue in wenigen Minuten wieder vorbei, um die volle Produktvielfalt zu entdecken.',
    },
    closeNotification: {
        defaultMessage: 'Schließen',
    },
});

const ProductList = ({
    hasUiFilters = true,
    indexId,
    initialPageFilter,
    initialSearch,
    isLastList = true,
    lazyLoad,
    noResultFallback,
    pageId = '',
    pageTitle = '',
    pageType,
    showLocationFilter = true,
    trackingName,
    verticalPosition = 0,
}: ProductListProps): JSX.Element => {
    const { algoliaConfig, locale, settingCookie, tenant } = useFragmentContext<FragmentContext>();
    const { getResults, status } = useAlgoliaContext();
    const [isMapViewModalOpen, setIsMapViewModalOpen] = useState(false);
    const defaultGridView = getGridView(settingCookie);
    const [gridView, setGridView] = useState(defaultGridView);
    const { dispatchSearch, geoLocationError, searchState } = useSearchState(
        gridView,
        isMapViewModalOpen,
        initialSearch,
    );

    const isFailedAlgoliaRequest = algoliaConfig.serverState?.isFailedRequest;
    const [showNotification, setShowNotification] = useState(isFailedAlgoliaRequest);

    const initialFilter = useMemo(
        () => ({
            ...initialSearch?.filter,
            travelNights: initialPageFilter?.travelNightsFilter,
            price: initialPageFilter?.priceFilter,
            location: initialPageFilter?.geoDistanceFilter?.location
                ? {
                      ...initialPageFilter?.geoDistanceFilter?.location,
                      distance: initialPageFilter?.geoDistanceFilter?.distance,
                  }
                : null,
            participants: initialPageFilter?.participants,
            topCountryCodes: initialPageFilter?.topCountryCodes,
            productAttributes: initialPageFilter?.productAttributes,
        }),
        [initialPageFilter, initialSearch?.filter],
    );

    const getIndex = (objectType: ObjectType, sorting?: Sorting): string =>
        getAlgoliaIndex({
            environment: algoliaConfig.environment,
            locale,
            tenant,
            objectType,
            sorting: !isFailedAlgoliaRequest
                ? sorting
                : { field: SortingField.SalesRank, order: SortingOrder.Desc },
        });

    const productIndex = getIndex(ObjectType.Product, searchState.sorting);
    const productResults = getResults<AlgoliaProduct>(indexId, productIndex);

    const intl = useIntl();

    const gridViewText = {
        tileViewText: intl.formatMessage(messages.tileViewText),
        listViewText: intl.formatMessage(messages.listViewText),
    };

    const onGridSwitch = (view: GridView): void => {
        setGridView(view);
        getSettingStorage().setItem(
            SettingStorageProperties.View,
            view === GridView.ListView ? ProductView.List : ProductView.Tile,
        );
        trackPLPViewChange(
            view === GridView.TileView ? gridViewText.tileViewText : gridViewText.listViewText,
            view === GridView.TileView ? GA4EventLabel.TileView : GA4EventLabel.ListView,
        );
    };

    const [searchResults, setSearchResults] = useState(productResults);

    const algoliaSearchState = useAlgoliaProductSearchState({
        indexName: productIndex,
        searchState,
        pageId,
    });

    useEffect(() => {
        // eslint-disable-next-line no-underscore-dangle
        const internalAlgoliaState = productResults?._state;

        if (!internalAlgoliaState) return;

        // Algolia internal result cache does not update early enough when we're switching between index
        // which causes a flicker (since we receive a cached wrong result momentarily).
        // This guard is to check if the result matches current query.
        const searchParameters = new SearchParameters(algoliaSearchState);

        const searchParametersMatchInternalAlgoliaState = isMatch(
            internalAlgoliaState,
            searchParameters,
        );

        if (status === SearchStatus.Resolved && searchParametersMatchInternalAlgoliaState) {
            setSearchResults(productResults);
        }
    }, [algoliaSearchState, productResults, status]);

    useEffect(() => {
        if (pageType === PageType.SearchPage && !!searchState.searchTerm) {
            const searchResultCount = getAdjustedItemCountByLimit(
                searchResults?.nbHits,
                searchState?.pagination?.limit,
            );
            trackSearchResultPage(searchState.searchTerm, String(searchResultCount));
        } else if (trackingName === 'NoResult' && pageType === PageType.ProductListingPage) {
            trackSearchResultPage('', '0');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleCustomEvent = (): void => {
            setIsMapViewModalOpen(true);
        };

        document.addEventListener(eventTypes.SHOW_MAP_VIEW, handleCustomEvent);

        return () => {
            document.removeEventListener(eventTypes.SHOW_MAP_VIEW, handleCustomEvent);
        };
    }, []);

    return (
        <>
            <ProductListSearchHeadline
                pageType={pageType}
                searchState={searchState}
                initialSearch={initialSearch}
                productResults={searchResults}
                showLocationFilter={showLocationFilter && !isFailedAlgoliaRequest}
            />
            {showNotification && (
                <Notification
                    variant={NotificationVariant.Warning}
                    message={intl.formatMessage(messages.isFailedAlgoliaRequest)}
                    a11yText={intl.formatMessage(messages.closeNotification)}
                    onRequestClose={() => setShowNotification(false)}
                />
            )}
            <ProductListFilters
                getIndex={getIndex}
                searchState={searchState}
                dispatchSearch={dispatchSearch}
                initialSearch={initialSearch}
                initialPageFilter={initialPageFilter}
                initialFilter={initialFilter as Filter}
                hasUiFilters={hasUiFilters}
                showLocationFilter={showLocationFilter}
                productResults={searchResults}
                geoLocationError={geoLocationError}
                onGridSwitch={onGridSwitch}
                gridView={gridView}
                isFailedAlgoliaRequest={isFailedAlgoliaRequest}
            />
            <Index indexName={productIndex} indexId={indexId}>
                <ProductListContent
                    hasUiFilters={hasUiFilters && !isFailedAlgoliaRequest}
                    showLocationFilter={showLocationFilter && !isFailedAlgoliaRequest}
                    isLastList={isLastList}
                    pageType={pageType}
                    initialPageFilter={initialPageFilter}
                    noResultFallback={noResultFallback}
                    lazyLoad={lazyLoad}
                    verticalPosition={verticalPosition}
                    pageId={pageId}
                    pageTitle={pageTitle}
                    searchState={searchState}
                    indexName={productIndex}
                    productResults={searchResults}
                    gridView={gridView}
                />
                {isMapViewModalOpen && !isFailedAlgoliaRequest && (
                    <HoverProvider>
                        <LoadableMapViewModal
                            initialSearch={initialSearch}
                            showLocationFilter={showLocationFilter}
                            pageType={pageType}
                            initialPageFilter={initialPageFilter}
                            initialFilter={{ ...initialFilter, boundary: null } as Filter}
                            lazyLoad={lazyLoad}
                            pageId={pageId}
                            indexName={productIndex}
                            productResults={searchResults}
                            pageTitle={pageTitle}
                            searchState={searchState}
                            dispatchSearch={dispatchSearch}
                            geoLocationError={geoLocationError}
                            isMapViewModalOpen={isMapViewModalOpen}
                            setModalOpen={setIsMapViewModalOpen}
                        />
                    </HoverProvider>
                )}
            </Index>
        </>
    );
};

export { ProductList };
