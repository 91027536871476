import { type ChangeEvent, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, trackFilterInteraction } from '@jsmdg/tracking';
import {
    FilterDropdownBase,
    RadioButton,
    RadioButtonGroup,
    type SelectOption,
    TripBoldIcon,
} from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../../../../shared/types/fragmentContext';
import { type Filter } from '../../../../../../shared/types/search';
import { getActiveFilters } from '../../../../../hooks/getActiveFilters';
import styles from '../common.module.scss';

type CategoriesSelectProps = {
    readonly categories: SelectOption[];
    readonly onSelect: (category: SelectOption) => void;
    readonly filter: Filter;
};

const messages = defineMessages({
    label: {
        defaultMessage: 'Was?',
    },
});

const handleClickOutside = (): void => {};

const CategoriesSelect = ({ categories, filter, onSelect }: CategoriesSelectProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<FragmentContext>();
    const filterRef = useRef<HTMLDivElement>(null);
    const [selectedCategory, setSelectedCategory] = useState<SelectOption>();

    const handleOnChange = async (selection: string, evt: ChangeEvent): Promise<void> => {
        const target = evt.target as HTMLInputElement;
        const label = target.labels?.[0].innerText;

        const category = { label: label ?? tenantConfig.allExperiencesLabel, value: selection };
        setSelectedCategory(category);
        onSelect(category);
        // close filter dropdown
        filterRef.current?.querySelector('button')?.click();

        if (label) {
            await window.yieldToMainThread();
            if (label === tenantConfig.allExperiencesLabel) {
                trackFilterInteraction(
                    'Reset_Category',
                    undefined,
                    {
                        eventName: GA4EventName.ResetFilter,
                        filter_type: 'Category',
                    },
                    getActiveFilters(filter),
                );
            } else {
                trackFilterInteraction(
                    'SetCategory',
                    label,
                    {
                        eventName: GA4EventName.SetFilter,
                        filter_type: 'Category',
                        filter_value: label,
                    },
                    getActiveFilters(filter, ['Category']),
                );
            }
        }
    };

    return (
        <div
            className={classNames(styles.wrapper, 'd-flex flex-xs-column w-100 p-0', {
                [styles.isActive]: !!selectedCategory,
            })}
            ref={filterRef}
        >
            <div className="d-flex align-items-start">
                <TripBoldIcon className={classNames(styles.icon, 'ml-1x mr-0-5x')} />
                <p className={classNames(styles.label, 'd-none d-xs-block fw-semibold')}>
                    {intl.formatMessage(messages.label)}
                </p>
            </div>

            <FilterDropdownBase
                className={styles.filterDropdown}
                label={selectedCategory?.label ?? tenantConfig.allExperiencesLabel}
                hasFormFooter={false}
                onClickOutside={handleClickOutside}
                hasChanged
                filterDropdownButtonClasses={classNames(
                    styles.filterButton,
                    'w-100 py-0 pl-0-5x pl-xs-2x justify-content-between',
                    { [styles.placeholder]: !selectedCategory },
                )}
                filterDropdownOverlayClasses={classNames(
                    styles.filterDropdownOverlay,
                    'mt-2x mt-xs-1-5x',
                )}
            >
                <RadioButtonGroup
                    name="categories"
                    vertical
                    className="px-3x"
                    value={selectedCategory?.value ?? categories[0]?.value}
                    onChange={handleOnChange}
                >
                    {categories.map(category => {
                        return (
                            <RadioButton
                                value={category.value}
                                key={category.label}
                                id={category.label}
                            >
                                <span>{category.label}</span>
                            </RadioButton>
                        );
                    })}
                </RadioButtonGroup>
            </FilterDropdownBase>
        </div>
    );
};

export { CategoriesSelect };
