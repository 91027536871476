/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { CloseIcon, Input, PureButton } from '@jsmdg/yoshi';
import { type Filter } from '../../../../../shared/types/search';
import { useLocationFilter } from '../../../../hooks';
import { type SearchReducerActionType } from '../../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../../types/searchReducer';
import { SuggestionList } from '../../../SuggestionList';
import { CurrentLocationButton } from './CurrentLocationButton';
import styles from './LocationFilter.module.scss';

type LocationFilterProps = {
    readonly locationName?: string;
    readonly shouldReset?: false;
    readonly err?: GeolocationPositionError;
    readonly onSubmit: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly filter?: Filter;
    readonly listType?: GA4FilterListType;
};

const LocationFilter = ({
    err,
    filter,
    listType,
    locationName = '',
    onSubmit,
    shouldReset,
}: LocationFilterProps): JSX.Element => {
    const {
        errorMessage,
        filterRef,
        formatMessage,
        handleKeyDown,
        isActive,
        location,
        locationSuggestions,
        messages,
        onClearLocation,
        onLocationChange,
        onSuggestionClick,
        selected,
        setIsActive,
        setSelected,
    } = useLocationFilter({
        locationName,
        onSubmit,
        shouldReset,
        err,
        filter,
        listType,
    });

    const [isErrorTooltipVisible, setIsErrorTooltipVisible] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            setIsErrorTooltipVisible(true);
        }
    }, [errorMessage]);

    return (
        <div className="mb-1-5x mb-md-0 mr-sm-1-5x" data-testid="experience-finder-location-filter">
            <form
                // autoComplete with "off" in the form tag is for firefox to stop it from autofill the inputs
                // the input contains another autoComplete attribute for chrome with an other value (see yoshi)
                autoComplete="off"
                className="position-relative"
                onSubmit={() => null}
                ref={filterRef}
            >
                <Input
                    className={styles.locationInput}
                    inputClasses="py-1-5x pr-5x pl-2x m-0"
                    name="locationSearch"
                    onClick={() => setIsActive(!isActive)}
                    onInput={onLocationChange}
                    onKeyDown={handleKeyDown}
                    placeholder={formatMessage(messages.placeholderLong)}
                    hideLabel
                    value={location || ''}
                    rightComponent={
                        <>
                            {location && (
                                <PureButton
                                    aria-label="clear location"
                                    className={styles.clearButton}
                                    onClick={onClearLocation}
                                >
                                    <CloseIcon />
                                </PureButton>
                            )}
                            {!errorMessage && (
                                <CurrentLocationButton location={location} onSubmit={onSubmit} />
                            )}
                        </>
                    }
                />

                {errorMessage && isErrorTooltipVisible ? (
                    <div className={classNames(styles.locationErrorTooltip, 'position-absolute')}>
                        <PureButton
                            onClick={() => setIsErrorTooltipVisible(false)}
                            className={classNames(
                                styles.tooltipButton,
                                'position-absolute p-0 m-0',
                            )}
                        >
                            <CloseIcon />
                        </PureButton>
                        <p>{errorMessage}</p>
                    </div>
                ) : null}
                {locationSuggestions && isActive && (
                    <SuggestionList
                        selected={selected}
                        setSelected={setSelected}
                        suggestions={locationSuggestions}
                        suggestionClick={onSuggestionClick}
                    />
                )}
            </form>
        </div>
    );
};

export { LocationFilter };
