import { FormattedMessage } from 'react-intl';
import { type SearchResults } from 'algoliasearch-helper';

import { PageType } from '../../../shared/enums/pageType';
import { type Search } from '../../../shared/types/search';
import { type AlgoliaProduct } from '../../../shared/types/searchResponse';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import { type SearchReducerState } from '../../reducers/searchReducer';
import styles from './ProductList.module.scss';

type ProductListSearchHeadlineProps = {
    readonly pageType: PageType;
    readonly showLocationFilter: boolean;
    readonly initialSearch?: Search;
    readonly searchState: SearchReducerState;
    readonly productResults?: SearchResults<AlgoliaProduct>;
};

const ProductListSearchHeadline = ({
    initialSearch,
    pageType,
    productResults,
    searchState,
    showLocationFilter,
}: ProductListSearchHeadlineProps): JSX.Element | null => {
    const isSearchPage = pageType === PageType.SearchPage;
    const { searchTerm } = searchState;

    const { hasNoResults } = useAlgoliaProducts(
        productResults,
        initialSearch?.pagination,
        showLocationFilter,
    );

    if (!searchTerm || (!isSearchPage && hasNoResults)) {
        return null;
    }

    return (
        <h1 key="headline" className="pr-1-5x pb-6x">
            <FormattedMessage
                defaultMessage="Suchergebnis zu &ldquo;{searchTerm}&ldquo;"
                values={{
                    searchTerm: <span className={styles.searchTerm}>{searchTerm}</span>,
                }}
            />
        </h1>
    );
};

export { ProductListSearchHeadline };
