import { useState } from 'react';
import { Configure } from 'react-instantsearch';
import { type SearchResults } from 'algoliasearch-helper';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Breakpoint, GridView, useBreakpoint } from '@jsmdg/yoshi';
import { pageConfig, PageType } from '../../../shared/enums/pageType';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type AlgoliaProduct } from '../../../shared/types/searchResponse';
import { dispatchEvent } from '../../helper/EventUtils';
import { useProductListItems } from '../../hooks';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import { useAlgoliaProductSearchState } from '../../hooks/useAlgoliaProductSearchState';
import { useTrackProductListItems } from '../../hooks/useTrackProductListItems';
import { useUrlQueryUpdate } from '../../hooks/useUrlQueryUpdate';
import { type SearchReducerState } from '../../reducers/searchReducer';
import { eventTypes, type InitialPageFilterType } from '../../types';
import { AddToCartErrorModal } from '../addToCartErrorModal/AddToCartErrorModal';
import { MixedShoppingCartErrorModal } from '../addToCartErrorModal/MixedShoppingCartErrorModal';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import NoListingSearchResult from '../NoResult/NoListingSearchResult';
import { NoResultsBoundary } from '../NoResult/NoResultsBoundary';
import { addToCartError } from './errors';
import { ProductListItems } from './ProductListItems';

type ProductListContentProps = {
    readonly hasUiFilters: boolean;
    readonly showLocationFilter: boolean;
    readonly isLastList: boolean;
    readonly pageType: PageType;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly noResultFallback?: JSX.Element;
    readonly lazyLoad: boolean;
    readonly verticalPosition?: number;
    readonly pageId?: string;
    readonly pageTitle?: string;
    readonly searchState: SearchReducerState;
    readonly indexName: string;
    readonly productResults?: SearchResults<AlgoliaProduct>;
    readonly gridView: GridView;
};

const handleCartRedirection = (): void => {
    dispatchEvent(eventTypes.ADD_TO_CART);
};

const ProductListContent = ({
    gridView,
    hasUiFilters,
    indexName,
    initialPageFilter,
    isLastList,
    lazyLoad,
    noResultFallback,
    pageId = '',
    pageTitle = '',
    pageType,
    productResults,
    searchState,
    showLocationFilter,
    verticalPosition,
}: ProductListContentProps): JSX.Element => {
    const { status } = useAlgoliaContext();
    const { tenant } = useFragmentContext<FragmentContext>();

    const { filter = {}, pagination, searchTerm, sorting } = searchState;
    const isDesktop = useBreakpoint(Breakpoint.SM);

    const [addToCartErrorType, setAddToCartErrorType] = useState('');

    const algoliaSearchState = useAlgoliaProductSearchState({
        indexName,
        searchState,
        pageId,
    });

    const { hasNoResults, nbPages, products } = useAlgoliaProducts(
        productResults,
        pagination,
        showLocationFilter,
    );

    const {
        nbBanners,
        productListCreatives,
        productListItems,
        productTileCreatives,
        selectionBlockCreatives,
    } = useProductListItems(products, gridView === GridView.ListView, pageId, tenant);

    const isSearchPage = pageType === PageType.SearchPage;

    const urlQueryUpdateParams = {
        filter,
        status,
        pagination,
        searchTerm,
        initialPageFilter,
        sorting,
    };

    useTrackProductListItems(products);

    useUrlQueryUpdate(urlQueryUpdateParams);

    const handleAddToCartErrorModalClose = (): void => {
        setAddToCartErrorType('');
    };

    const { isFullWidth, showBanner, showFeedback, size, sizeMobile, useOffset } =
        pageConfig[pageType];
    const currentPageSize = (!isDesktop ? sizeMobile : size) + nbBanners;

    const offset =
        useOffset && sizeMobile !== size
            ? // only add offset if values differ, else we use page number to paginate
              currentPageSize
            : 0;
    const showFeedbackForm = showFeedback && isLastList;

    return (
        <>
            <Configure {...algoliaSearchState} />
            <NoResultsBoundary
                hasNoResults={hasNoResults}
                fallback={noResultFallback || <NoListingSearchResult />}
            >
                <ProductListItems
                    productListItems={productListItems}
                    currentPageSize={currentPageSize}
                    pageType={pageType}
                    isFullWidth={isFullWidth}
                    lazyLoad={lazyLoad}
                    handleCartRedirection={handleCartRedirection}
                    setAddToCartErrorType={setAddToCartErrorType}
                    showBanner={showBanner}
                    hasUiFilters={hasUiFilters}
                    searchState={searchState}
                    productTileCreatives={productTileCreatives}
                    productListCreatives={productListCreatives}
                    selectionBlockCreatives={selectionBlockCreatives}
                    initialPageFilter={initialPageFilter}
                    nbPages={nbPages}
                    offset={offset}
                    verticalPosition={verticalPosition}
                    status={status}
                    queryId={productResults?.queryID}
                    indexName={indexName}
                    gridView={gridView}
                />
            </NoResultsBoundary>

            {showFeedbackForm && (
                <FeedbackForm
                    pageId={isSearchPage ? 'searchPage' : pageId}
                    pageTitle={isSearchPage ? 'searchPage' : pageTitle}
                />
            )}
            <AddToCartErrorModal
                onRequestClose={handleAddToCartErrorModalClose}
                isOpen={addToCartErrorType === addToCartError.GENERAL_CART_ERROR}
            />
            <MixedShoppingCartErrorModal
                onRequestClose={handleAddToCartErrorModalClose}
                onGoToCart={handleCartRedirection}
                isOpen={addToCartErrorType === addToCartError.MIXED_SHOPPING_ERROR}
            />
        </>
    );
};

export { ProductListContent };
