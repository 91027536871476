import { useMemo } from 'react';
import { type PlainSearchParameters } from 'algoliasearch-helper';

import { getAlgoliaSearchParameters } from '../../shared/helpers/algoliaHelpers';
import { type SearchReducerState } from '../reducers/searchReducer';

type UseAlgoliaSearchStateProps = {
    indexName: string;
    searchState: SearchReducerState;
    pageId?: string;
    facets?: string[];
};

const useAlgoliaProductSearchState = ({
    indexName,
    searchState,
    pageId,
    facets,
}: UseAlgoliaSearchStateProps): PlainSearchParameters => {
    return useMemo(
        () => ({
            ...getAlgoliaSearchParameters(searchState, pageId, facets),
            index: indexName,
        }),
        [facets, indexName, pageId, searchState],
    );
};

export { useAlgoliaProductSearchState };
