import { useEffect } from 'react';
import { Configure, Index, useInstantSearch } from 'react-instantsearch';
import { defineMessages, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Skeleton, SkeletonAnimation, SkeletonVariant } from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { getAlgoliaSearchParameters } from '../../../shared/helpers/algoliaHelpers';
import { getAlgoliaIndex } from '../../../shared/helpers/getAlgoliaIndex';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Product } from '../../../shared/types/searchResponse';
import { AlgoliaIndexId } from '../../enums/algoliaIndexId';
import { TrackingListName } from '../../enums/trackingListName';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import { ProductSlider } from '../ProductSlider';

const messages = defineMessages({
    lastSeenProducts: { defaultMessage: 'Zuletzt gesehen' },
});

const sortProducts = (productIdArray: string[], fetchedProductItems: Product[]): Product[] => {
    return productIdArray
        .map((id: string): Product | undefined =>
            fetchedProductItems.find((product: Product): boolean => product.productId === id),
        )
        .filter((productItem?: Product): boolean => productItem !== undefined) as Product[];
};

type LastSeenSliderContentProps = {
    readonly productIds: string[];
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly indexName: string;
    readonly verticalPosition?: number;
};

type LastSeenProductSliderProps = {
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly productIds?: string[];
    readonly indexId?: AlgoliaIndexId;
    readonly verticalPosition?: number;
};

const LastSeenSliderContent = ({
    indexName,
    lazyLoad,
    pageType,
    productIds,
    verticalPosition,
}: LastSeenSliderContentProps): JSX.Element | null => {
    const intl = useIntl();
    const { refresh, results } = useInstantSearch();
    const { products } = useAlgoliaProducts(results);

    useEffect(() => {
        // NB: just another Algolia quirk. After SSR, the client won't make any extra query.
        // If we have LastSeenProducts, we call refresh to run an extra query in the browser.
        refresh();
    }, [refresh]);

    return products.length ? (
        <ProductSlider
            products={sortProducts(productIds, products)}
            title={intl.formatMessage(messages.lastSeenProducts)}
            trackingCategory="ProductList"
            trackingListName={TrackingListName.LastSeen}
            dataTestId="last-seen-slider"
            lazyLoad={lazyLoad}
            pageType={pageType}
            indexName={indexName}
            queryId={results.queryID}
            verticalPosition={verticalPosition}
        />
    ) : null;
};

const LastSeenProductSlider = ({
    indexId = AlgoliaIndexId.LastSeen,
    lazyLoad,
    pageType,
    productIds = [],
    verticalPosition,
}: LastSeenProductSliderProps): JSX.Element | null => {
    const { algoliaConfig, locale, tenant } = useFragmentContext<FragmentContext>();
    const { status } = useAlgoliaContext();

    if (!productIds.length) {
        return null;
    }

    const indexName = getAlgoliaIndex({
        environment: algoliaConfig.environment,
        tenant,
        locale,
    });

    const search = {
        pagination: {
            offset: 0,
            size: productIds.length,
            currentPage: 1,
        },
        filter: {
            productId: productIds,
        },
    };

    return (
        <>
            {status === SearchStatus.Pending && (
                <Skeleton
                    animation={SkeletonAnimation.Wave}
                    variant={SkeletonVariant.Rect}
                    width="100%"
                    height="300px"
                />
            )}

            <Index indexName={indexName} indexId={indexId}>
                <Configure {...getAlgoliaSearchParameters(search)} />
                <LastSeenSliderContent
                    productIds={productIds}
                    lazyLoad={lazyLoad}
                    pageType={pageType}
                    indexName={indexName}
                    verticalPosition={verticalPosition}
                />
            </Index>
        </>
    );
};

export { LastSeenProductSlider };
