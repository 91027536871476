import { useMemo } from 'react';
import { createBrowserLocalStorageCache } from '@algolia/cache-browser-local-storage';
import { createFallbackableCache } from '@algolia/cache-common';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { LogLevelEnum } from '@algolia/logger-common';
import { createConsoleLogger } from '@algolia/logger-console';
import algoliasearch, { type SearchClient } from 'algoliasearch/lite';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { getAlgoliaIndex } from '../../shared/helpers/getAlgoliaIndex';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Search } from '../../shared/types/search';
import { shuffle } from '../helper/arrayUtils';

type UseAlgoliaReturn = {
    client: SearchClient;
    indexName: string;
};

export const useAlgolia = (search?: Search): UseAlgoliaReturn => {
    const { algoliaConfig, locale, tenant, env } = useFragmentContext<FragmentContext>();
    const client: SearchClient = useMemo(
        () =>
            algoliasearch(algoliaConfig.appId, algoliaConfig.searchKey, {
                timeouts: algoliaConfig.timeouts,
                hosts: [{ url: `${algoliaConfig.appId}-dsn.algolia.net` }].concat(
                    shuffle([
                        { url: `${algoliaConfig.appId}-1.algolia.net` },
                        { url: `${algoliaConfig.appId}-2.algolia.net` },
                        { url: `${algoliaConfig.appId}-3.algolia.net` },
                    ]),
                ),

                responsesCache: createFallbackableCache({
                    caches: [
                        createBrowserLocalStorageCache({
                            key: `algolia-responses`,
                            timeToLive: 900, // 15min
                        }),
                        createInMemoryCache(),
                    ],
                }),

                // Caches Promises with the same request payload
                requestsCache: createFallbackableCache({
                    caches: [
                        createBrowserLocalStorageCache({
                            key: `algolia-requests`,
                            timeToLive: 900, // 15min
                        }),
                        createInMemoryCache(),
                    ],
                }),

                logger: createConsoleLogger(
                    env === 'prod' ? LogLevelEnum.Error : LogLevelEnum.Debug,
                ),
            }),
        [algoliaConfig.appId, algoliaConfig.searchKey, algoliaConfig.timeouts, env],
    );

    const indexName = useMemo(
        () =>
            getAlgoliaIndex({
                locale,
                tenant,
                environment: algoliaConfig.environment,
                sorting: search?.sorting,
            }),
        [locale, tenant, algoliaConfig.environment, search?.sorting],
    );

    return { client, indexName };
};
