import { useEffect, useReducer, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    type FilterGA4Data,
    GA4EventName,
    GA4FilterListType,
    trackFilterInteraction,
} from '@jsmdg/tracking';
import {
    Breakpoint,
    Button,
    ButtonColor,
    RenderType,
    SearchIcon,
    type SelectOption,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { PriceFilterDefault } from '../../enums/priceFilterDefault';
import { formatTrackingPriceRange } from '../../helper/formatPrice';
import { getGeoPosition } from '../../helper/getGeoPosition';
import { toUrlSearchParameters } from '../../helper/mapUrlParameters';
import { storageAvailable } from '../../helper/storageHelper';
import { getActiveFilters } from '../../hooks/getActiveFilters';
import { trackLocateMeClicked } from '../../hooks/locationFilter.tracking';
import { searchReducer, SearchReducerActionType } from '../../reducers/searchReducer';
import { type SearchReducerValue } from '../../types/searchReducer';
import { CategoriesSelect } from './CategoriesSelect';
import { LocationFilter } from './Filters/Location';
import { PriceFilter } from './Filters/Price';
import styles from './ExperienceFinder.module.scss';

const messages = defineMessages({
    headline: { defaultMessage: 'Erlebnisfinder' },
    cta: { defaultMessage: 'Suchen' },
    info: { defaultMessage: 'Finde jetzt dein passendes Erlebnis' },
});

type ExperienceFinderProps = {
    readonly categories: SelectOption[];
};

const PRICE_BOUNDARY_MIN = PriceFilterDefault.Min;
const PRICE_BOUNDARY_MAX = PriceFilterDefault.Max;

const ExperienceFinder = ({ categories }: ExperienceFinderProps): JSX.Element => {
    const intl = useIntl();
    const { locale, reverseGeocode, tenantConfig } = useFragmentContext<FragmentContext>();
    const { currency } = tenantConfig;

    const [categoryPath, setCategoryPath] = useState(
        categories.length ? categories[0].value : tenantConfig.allExperiencesPath,
    );
    const [categoryLabel, setCategoryLabel] = useState(
        categories.length ? categories[0].label : tenantConfig.allExperiencesLabel,
    );
    const [filterQuery, setFilterQuery] = useState('');
    const [{ filter = {}, type: searchStateType }, dispatchSearch] = useReducer(searchReducer, {});
    const [geoLocationError, setGeoLocationError] = useState<GeolocationPositionError>();
    const isDesktop = useBreakpoint(Breakpoint.SM);

    const handleFilterChange = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
    ): void => {
        dispatchSearch({ type, value });
    };

    const updatePathAndLabel = (path: string, label: string): void => {
        setCategoryPath(path);
        setCategoryLabel(label);
    };

    const getCtaTrackingValue = (): string => {
        const location = filter.location
            ? `${filter.location.name || ''}_${filter.location.distance}km`
            : 'not selected';
        const { max = PRICE_BOUNDARY_MAX, min = PRICE_BOUNDARY_MIN } = filter?.price || {};
        const priceRange = formatTrackingPriceRange({
            locale,
            currencyCode: currency.code,
            min,
            max,
        });
        return `${location}_${categoryLabel}_${priceRange}`;
    };

    const trackCtaClick = async (): Promise<void> => {
        await window.yieldToMainThread();

        const ctaGA4Data: FilterGA4Data = {
            eventName: GA4EventName.ClickButton,
            click_element: 'experience finder CTA',
            click_text: intl.formatMessage(messages.cta),
        };

        const activeFilterString =
            categoryPath === tenantConfig.allExperiencesLabel
                ? getActiveFilters(filter)
                : getActiveFilters(filter, ['Category']);

        trackFilterInteraction('SetFilter', getCtaTrackingValue(), ctaGA4Data, activeFilterString);
    };

    useEffect(() => {
        setFilterQuery(`?${toUrlSearchParameters({ filter })}`);
    }, [filter]);

    useEffect(() => {
        setGeoLocationError(filter.error);
    }, [filter.error]);

    useEffect(() => {
        if (isDesktop) {
            setCategoryPath(
                categories.length ? categories[0].value : tenantConfig.allExperiencesPath,
            );
            setCategoryLabel(
                categories.length ? categories[0].label : tenantConfig.allExperiencesLabel,
            );
        }
    }, [categories, isDesktop, tenantConfig]);

    useEffect(() => {
        if (searchStateType === 'geoCoordinates') {
            (async () => {
                let geoPosition;
                try {
                    geoPosition = await getGeoPosition();
                } catch (error) {
                    const locationPositionError = error as GeolocationPositionError;
                    if (
                        storageAvailable('sessionStorage') &&
                        window.GeolocationPositionError &&
                        locationPositionError.code ===
                            window.GeolocationPositionError.PERMISSION_DENIED
                    ) {
                        window.sessionStorage.setItem('locationApiAccepted', 'false');
                    }

                    setGeoLocationError(locationPositionError);

                    return;
                }

                if (storageAvailable('sessionStorage')) {
                    window.sessionStorage.setItem('locationApiAccepted', 'true');
                }

                let locationName;
                try {
                    const geocodeResponse = await reverseGeocode(
                        geoPosition.coords.latitude,
                        geoPosition.coords.longitude,
                    );
                    locationName = geocodeResponse.results[0].formatted_address;
                } catch {
                    locationName = 'no_location_name';
                }

                const activeFiltersString = getActiveFilters(filter, ['Location', 'distance']);
                await trackLocateMeClicked(
                    'LocateMe',
                    locationName,
                    activeFiltersString,
                    GA4FilterListType.ListView,
                );

                dispatchSearch({
                    type: SearchReducerActionType.Location,
                    value: {
                        lat: geoPosition.coords.latitude,
                        long: geoPosition.coords.longitude,
                        name: locationName,
                        distance: 100,
                    },
                });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reverseGeocode, searchStateType]);

    return (
        <div className={styles.experienceFinder} data-testid="experience-finder">
            <h2>{intl.formatMessage(messages.headline)}</h2>
            <span>{intl.formatMessage(messages.info)}</span>
            <div
                className={classNames(
                    styles.experienceFinderFilters,
                    'd-flex flex-column flex-md-row ',
                )}
            >
                <LocationFilter
                    locationName={filter.location?.name}
                    onSubmit={handleFilterChange}
                    err={geoLocationError}
                    filter={filter}
                />
                <PriceFilter
                    values={[null, null]}
                    options={{ min: PRICE_BOUNDARY_MIN, max: PRICE_BOUNDARY_MAX }}
                    onSubmit={handleFilterChange}
                    currencyCode={currency.code}
                    filter={filter}
                />
                {categories.length && (
                    <CategoriesSelect
                        initialCategory={categories[0]}
                        onSelect={updatePathAndLabel}
                        options={categories}
                        filter={filter}
                    />
                )}
                <Button
                    href={`${categoryPath}${filterQuery}`}
                    className={classNames(
                        styles.cta,
                        'd-flex justify-content-center align-items-center',
                    )}
                    onClick={trackCtaClick}
                    data-testid="experience-finder-search-call-to-action"
                    iconLeft={<SearchIcon />}
                    color={ButtonColor.Complementary}
                    as={RenderType.Link}
                >
                    {intl.formatMessage(messages.cta)}
                </Button>
            </div>
        </div>
    );
};

export { ExperienceFinder };
