import axios from 'axios';

import { createFragment } from '@jsmdg/react-fragment-scripts/fragment';
import { Brand, Tenant } from '@jsmdg/tenancy';
import { type MailContent } from '../shared/types/contactForm';
import { type FragmentContext } from '../shared/types/fragmentContext';
import { type PageRoot } from '../shared/types/pageRoot';
import { Page } from './components/Page';
import { createErrorLogger } from './helper/createErrorLogger';
import { addProductToCart } from './services/addProductToCart';
import { performReverseGeocode } from './services/googleGeocode';
import { fetchPlacesDetail, fetchPlacesSuggestions } from './services/googlePlaces';
import { submitContactForm } from './services/submitContactForm';
import { submitFeedbackForm } from './services/submitFeedbackForm';
import { getTenantConfig } from './tenantConfig';

// eslint-disable-next-line @typescript-eslint/require-await
async function initDependencies({ baseUrl }: { baseUrl?: string }): Promise<void> {
    axios.defaults.baseURL = baseUrl;
}

const { Fragment, init } = createFragment<PageRoot, FragmentContext>(
    Page,
    ({ rootComponentProps, locale, tenant }) => {
        const {
            path = '',
            campaignCreatives,
            featureFlagsVariation,
            type,
            showBetaFeatures,
            customerState,
            initialWishlist,
            logger,
            algoliaConfig,
            sharedData,
            tenantContacts,
            settingCookie,
            env,
            isShoppingFlow,
        } = rootComponentProps || {};

        return {
            getPlacesSuggestions: async (input: string, sessionToken: string) =>
                fetchPlacesSuggestions({
                    input,
                    sessionToken,
                    locale,
                }),
            getPlacesDetail: async (placeId: string, sessionToken: string) =>
                fetchPlacesDetail({
                    placeId,
                    sessionToken,
                    locale,
                }),
            reverseGeocode: async (lat: number, long: number) =>
                performReverseGeocode({ lat, long, locale }),
            addProductToCart: async (productId: string, quantity: number) =>
                addProductToCart(productId, quantity),
            submitContactForm: async (data: MailContent) => submitContactForm(data),
            submitFeedbackForm,
            locale,
            tenant,
            path,
            campaignCreatives,
            featureFlagsVariation,
            type,
            showBetaFeatures,
            customerState,
            initialWishlist,
            errorLogger: createErrorLogger(logger),
            algoliaConfig,
            tenantConfig: {
                ...getTenantConfig(tenant),
                ...sharedData,
                tenantContacts,
            },
            isMydays: new Tenant(tenant).belongsTo(Brand.Mydays),
            settingCookie,
            env,
            isShoppingFlow,
        };
    },
    initDependencies,
);

export { Fragment, init };
