import { type Dispatch, useMemo, useRef } from 'react';
import { Configure, Index } from 'react-instantsearch';
import { type SearchResults } from 'algoliasearch-helper';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { GA4FilterListType } from '@jsmdg/tracking';
import { Breakpoint, GridSwitcher, type GridView, useBreakpoint } from '@jsmdg/yoshi';
import { AttributeIndexField } from '../../../shared/enums/indexField';
import { ObjectType } from '../../../shared/enums/objectType';
import { getFacetFilters } from '../../../shared/helpers/algoliaHelpers';
import { type Attribute } from '../../../shared/types/attribute';
import { type FacetFilter } from '../../../shared/types/facetFilter';
import {
    type Filter as FilterType,
    type Search,
    type Sorting as SortingType,
} from '../../../shared/types/search';
import { type AlgoliaProduct } from '../../../shared/types/searchResponse';
import { AlgoliaIndexId } from '../../enums/algoliaIndexId';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import {
    type SearchReducerAction,
    SearchReducerActionType,
    type SearchReducerState,
} from '../../reducers/searchReducer';
import { type InitialPageFilterType } from '../../types';
import { type SearchReducerValue } from '../../types/searchReducer';
import { Filter } from '../Filter';
import { LocationFilter } from '../Filter/Location';
import { Sorting } from '../Filter/Sorting';
import { MapContainer } from '../MapContainer/MapContainer';
import { ProductListCounter } from '../ProductListCounter';
import styles from './ProductList.module.scss';

type ProductListFiltersProps = {
    readonly getIndex: (objectType: ObjectType, sorting?: SortingType) => string;
    readonly initialSearch?: Search;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly initialFilter?: FilterType;
    readonly hasUiFilters?: boolean;
    readonly showLocationFilter?: boolean;
    readonly isFailedAlgoliaRequest?: boolean;
    readonly searchState: SearchReducerState;
    readonly dispatchSearch: Dispatch<SearchReducerAction>;
    readonly productResults?: SearchResults<AlgoliaProduct>;
    readonly geoLocationError?: GeolocationPositionError;
    readonly onGridSwitch: (view: GridView) => void;
    readonly gridView: GridView;
};

const ProductListFilters = ({
    dispatchSearch,
    geoLocationError,
    getIndex,
    gridView,
    hasUiFilters,
    initialFilter,
    initialPageFilter,
    initialSearch,
    isFailedAlgoliaRequest = false,
    onGridSwitch,
    productResults,
    searchState,
    showLocationFilter,
}: ProductListFiltersProps): JSX.Element => {
    const cachedAttributes = useRef<FacetFilter[] | undefined>(undefined);
    const attributeIndex = getIndex(ObjectType.Attribute);
    const { getResults } = useAlgoliaContext();
    const results = getResults<Attribute>(AlgoliaIndexId.Attributes, attributeIndex);

    const { limit, nbItems, products } = useAlgoliaProducts(
        productResults,
        initialSearch?.pagination,
        showLocationFilter,
    );

    const { filter = {}, pagination, sorting } = searchState;
    const locationFilterIsActive = showLocationFilter && !!filter.location?.name;
    const listType = GA4FilterListType[gridView];
    const isDesktop = useBreakpoint(Breakpoint.SM);
    const showMobileLocationFilter = hasUiFilters && showLocationFilter && !isDesktop;
    const filterChanged = !isEqual(filter, initialFilter);

    const attributes = useMemo(() => {
        if (!cachedAttributes.current?.length) {
            cachedAttributes.current = getFacetFilters(
                results?.hits,
                productResults,
                pagination?.limit,
            );
        }

        return cachedAttributes.current;
    }, [results?.hits, productResults, pagination?.limit]);

    const onReset = (): void => {
        dispatchSearch({
            type: SearchReducerActionType.SearchReset,
            value: {
                ...initialSearch,
                filter: initialFilter,
            },
        });
    };

    const onFilterChange = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ): void => {
        if (
            ![
                SearchReducerActionType.Filter,
                SearchReducerActionType.Pagination,
                SearchReducerActionType.Sorting,
            ].includes(type)
        ) {
            cachedAttributes.current = undefined;
        }

        dispatchSearch({ type, value, name });
    };

    return (
        <Index indexName={attributeIndex} indexId={AlgoliaIndexId.Attributes}>
            <Configure
                hitsPerPage={1_000}
                attributesToRetrieve={Object.values(AttributeIndexField)}
                attributesToHighlight={[]}
                attributesToSnippet={[]}
            />
            {showMobileLocationFilter && !isFailedAlgoliaRequest && (
                <div className="d-block d-sm-none">
                    <LocationFilter
                        locationName={filter.location?.name || ''}
                        onSubmit={(type: SearchReducerActionType, value?: SearchReducerValue) =>
                            onFilterChange(type, value)
                        }
                        geoLocationError={geoLocationError}
                        isOuter
                        listType={GA4FilterListType[gridView]}
                    />
                </div>
            )}
            {showLocationFilter && !isFailedAlgoliaRequest && (
                <div className="d-block d-sm-none">
                    <MapContainer />
                </div>
            )}

            {hasUiFilters && (
                <div
                    className={classNames(
                        styles.listControls,
                        'd-flex align-items-center justify-content-between position-relative d-sm-block',
                        { 'flex-row-reverse': !isFailedAlgoliaRequest },
                    )}
                >
                    {!isFailedAlgoliaRequest && (
                        <Filter
                            filter={filter}
                            sorting={sorting}
                            initialPageFilter={initialPageFilter}
                            itemCount={nbItems}
                            paginationLimit={limit}
                            onFilterChange={onFilterChange}
                            onSortingChange={onFilterChange}
                            onReset={onReset}
                            hasFilterChanged={filterChanged}
                            showLocationFilter={!!showLocationFilter}
                            locationFilterIsActive={!!locationFilterIsActive}
                            geoLocationError={geoLocationError}
                            facets={attributes}
                            gridView={gridView}
                        />
                    )}
                    {!!products.length && !!nbItems && (
                        <div className="d-flex justify-content-between align-items-center mb-sm-2-5x">
                            <ProductListCounter count={nbItems} />
                            <>
                                {!isFailedAlgoliaRequest && (
                                    <div className="d-none d-sm-flex ml-auto mr-0">
                                        <Sorting
                                            selectedSorting={sorting}
                                            onSubmit={onFilterChange}
                                            includeDistanceSorting={!!locationFilterIsActive}
                                            targetGroup={filter.targetGroup?.name}
                                            isDesktop
                                            listType={listType}
                                        />
                                    </div>
                                )}

                                <GridSwitcher
                                    defaultView={gridView}
                                    onSwitch={onGridSwitch}
                                    className="d-none d-md-flex ml-2x"
                                />
                            </>
                        </div>
                    )}
                </div>
            )}
        </Index>
    );
};

export { ProductListFilters };
