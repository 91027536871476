import { Configure, Index, useInstantSearch } from 'react-instantsearch';
import { defineMessages, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type PageType } from '../../../shared/enums/pageType';
import { getAlgoliaSearchParameters } from '../../../shared/helpers/algoliaHelpers';
import { getAlgoliaIndex } from '../../../shared/helpers/getAlgoliaIndex';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Search } from '../../../shared/types/search';
import { AlgoliaIndexId } from '../../enums/algoliaIndexId';
import { TrackingListName } from '../../enums/trackingListName';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import { ProductSlider } from '../ProductSlider';

type BestsellerSliderProps = {
    readonly initialSearch: Search;
    readonly moreResultsLink: string;
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly indexId?: string;
    readonly verticalPosition?: number;
};

const messages = defineMessages({
    bestsellerProducts: { defaultMessage: 'Beliebte Erlebnisse' },
    bestsellerShowMore: { defaultMessage: 'Mehr entdecken' },
});

type BestsellerSliderContentProps = {
    readonly initialSearch: Search;
    readonly moreResultsLink: string;
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly indexName: string;
    readonly verticalPosition?: number;
};

const BestsellerSliderContent = ({
    indexName,
    initialSearch,
    lazyLoad,
    moreResultsLink,
    pageType,
    verticalPosition,
}: BestsellerSliderContentProps): JSX.Element | null => {
    const intl = useIntl();
    const { results } = useInstantSearch();
    const { products } = useAlgoliaProducts(results, initialSearch?.pagination);

    return products.length ? (
        <ProductSlider
            products={products}
            title={intl.formatMessage(messages.bestsellerProducts)}
            urlTitle={intl.formatMessage(messages.bestsellerShowMore)}
            url={moreResultsLink}
            trackingCategory="ProductList"
            trackingListName={TrackingListName.Bestseller}
            dataTestId="bestseller-slider"
            lazyLoad={lazyLoad}
            pageType={pageType}
            indexName={indexName}
            queryId={results.queryID}
            verticalPosition={verticalPosition}
        />
    ) : null;
};

const BestsellerSlider = ({
    indexId = AlgoliaIndexId.BestSeller,
    initialSearch,
    lazyLoad,
    moreResultsLink,
    pageType,
    verticalPosition,
}: BestsellerSliderProps): JSX.Element | null => {
    const { algoliaConfig, locale, tenant } = useFragmentContext<FragmentContext>();
    const indexName = getAlgoliaIndex({
        environment: algoliaConfig.environment,
        tenant,
        locale,
        sorting: initialSearch?.sorting,
    });

    return (
        <Index indexName={indexName} indexId={indexId}>
            <Configure {...getAlgoliaSearchParameters(initialSearch)} />
            <BestsellerSliderContent
                initialSearch={initialSearch}
                moreResultsLink={moreResultsLink}
                lazyLoad={lazyLoad}
                pageType={pageType}
                indexName={indexName}
                verticalPosition={verticalPosition}
            />
        </Index>
    );
};

export { BestsellerSlider };
