import { type PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import {
    Configure,
    InstantSearch,
    type InstantSearchServerState,
    InstantSearchSSRProvider,
    useInstantSearch,
} from 'react-instantsearch';
import { type SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { type SearchResults } from 'algoliasearch-helper';
import { type InstantSearchStatus } from 'instantsearch.js/es/lib/InstantSearch';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { AlgoliaAnalyticsTag, enhanceAlgoliaAnalyticsTags } from '@jsmdg/tracking';
import { Breakpoint, useBreakpoint } from '@jsmdg/yoshi';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { getScopedResultFromServerState } from '../../../shared/helpers/algoliaHelpers';
import { createAlgoliaSearchContext } from '../../../shared/helpers/createAlgoliaSearchContext';
import { type AlgoliaSearchContext } from '../../../shared/types/algoliaSearchContext';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { useInsightsMiddleware } from '../../hooks/useInsightsMiddleware';

type PageWrapperProps = PropsWithChildren<{
    readonly client: SearchClient;
    readonly indexName: string;
    readonly pageId?: string;
}>;

type ContextProps = PropsWithChildren<{
    readonly serverState?: InstantSearchServerState;
    readonly client: SearchClient;
}>;

const formatAlgoliaStatus = (status: InstantSearchStatus): SearchStatus => {
    if (status === 'error') {
        return SearchStatus.Rejected;
    }

    return status === 'loading' || status === 'stalled'
        ? SearchStatus.Pending
        : SearchStatus.Resolved;
};

const WrapContext = ({ children, client, serverState }: ContextProps): JSX.Element => {
    const {
        addMiddlewares,
        error,
        scopedResults: algoliaScopedResults,
        status: algoliaStatus,
    } = useInstantSearch();
    const [status, setStatus] = useState(SearchStatus.Idle);
    const statusRef = useRef(algoliaStatus);

    const insightsMiddleware = useInsightsMiddleware();

    useMemo(() => {
        if (insightsMiddleware) {
            addMiddlewares(insightsMiddleware);
        }
    }, [addMiddlewares, insightsMiddleware]);

    // ScopedResults from initial load only includes result states from the primary query
    // This causes some components to not render in SSR. We initialize our own scopedResults here
    // to work around this Algolia internal issue.
    const [scopedResults, setScopedResults] = useState(() =>
        getScopedResultFromServerState(client, algoliaScopedResults, serverState),
    );

    useEffect(() => {
        if (statusRef.current !== algoliaStatus) {
            statusRef.current = algoliaStatus;
            setStatus(formatAlgoliaStatus(algoliaStatus));
        }
    }, [algoliaStatus]);

    useEffect(() => {
        setScopedResults(algoliaScopedResults);
    }, [algoliaScopedResults]);

    const contextValue: AlgoliaSearchContext = useMemo(
        () => ({
            error,
            status,
            getResults<T>(indexId: string, indexName: string): SearchResults<T> | undefined {
                return scopedResults.find(
                    scopedResult =>
                        scopedResult.indexId === indexId &&
                        scopedResult.results?.index === indexName,
                )?.results;
            },
        }),
        [error, scopedResults, status],
    );

    return (
        <createAlgoliaSearchContext.Provider value={contextValue}>
            {children}
        </createAlgoliaSearchContext.Provider>
    );
};

const PageWrapper = ({ children, client, indexName, pageId }: PageWrapperProps): JSX.Element => {
    const { algoliaConfig } = useFragmentContext<FragmentContext>();
    const { userToken } = algoliaConfig;
    const isDesktop = useBreakpoint(Breakpoint.MD);

    const analyticsTags = enhanceAlgoliaAnalyticsTags({
        analyticsTags: pageId ? [AlgoliaAnalyticsTag.Plp, pageId] : [AlgoliaAnalyticsTag.Search],
        isDesktop,
    });

    return (
        <InstantSearchSSRProvider {...algoliaConfig.serverState}>
            <InstantSearch searchClient={client} indexName={indexName}>
                <Configure
                    userToken={userToken}
                    clickAnalytics={!!userToken}
                    analyticsTags={analyticsTags}
                    hitsPerPage={0}
                />
                <WrapContext client={client} serverState={algoliaConfig.serverState}>
                    {children}
                </WrapContext>
            </InstantSearch>
        </InstantSearchSSRProvider>
    );
};

export { PageWrapper };
