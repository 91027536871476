import { type MouseEvent } from 'react';
import { type IntlShape } from 'react-intl';

import { AlgoliaAnalyticsDataSource, getAlgoliaAnalyticsStorage } from '@jsmdg/browser-storage';
import { logToSentry } from '@jsmdg/react-fragment-scripts/fragment';
import {
    AlgoliaAnalyticsEventAction,
    AlgoliaAnalyticsEventPage,
    AlgoliaAnalyticsEventSource,
    GA4EventName,
    GA4FeatureCategory,
    GA4ItemListName,
    GA4ItemListType,
    sendAlgoliaObjectIdsClickedAfterSearchEvent,
    trackEecProductClick,
    trackEecProductImpression,
} from '@jsmdg/tracking';
import { type Product } from '../../shared/types/searchResponse';
import { TrackingListName } from '../enums/trackingListName';
import { enhanceUrlWithSearchParams } from '../helper/enhanceUrlWithSearchParams';
import { useImpressionTracking } from './useImpressionTracking';
import { useInsightsClient } from './useInsightsClient';
import { useProductTileData } from './useProductTileData';

const isQuotaExceededError = (e: Error): boolean => {
    return e.message?.toLowerCase().includes('quota');
};

type UseOnProductTileClickAndImpressionReturnType = {
    setImpressionTrackedElement: (element: HTMLAnchorElement | HTMLDivElement | null) => void;
    onProductTileClick: (event?: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const useOnProductTileClickAndImpression = (
    product: Product,
    position: number,
    intl: IntlShape,
    listInfo: {
        isListView?: boolean;
        indexName?: string;
        queryId?: string;
        trackingListName?: TrackingListName;
        hasLocationFilter?: boolean;
        isModalView?: boolean;
        isOverlay?: boolean;
    },
): UseOnProductTileClickAndImpressionReturnType => {
    const {
        isListView,
        indexName,
        queryId,
        trackingListName,
        hasLocationFilter,
        isModalView,
        isOverlay,
    } = listInfo;

    let listType = GA4ItemListType.ListView;

    if (isModalView) {
        listType = isOverlay ? GA4ItemListType.MapViewOverlay : GA4ItemListType.MapViewList;
    } else if (!isListView) {
        listType = GA4ItemListType.TileView;
    }

    let itemListName = GA4ItemListName.Category;

    if (trackingListName === TrackingListName.Bestseller) {
        itemListName = GA4ItemListName.Bestseller;
    } else if (trackingListName === TrackingListName.LastSeen) {
        itemListName = GA4ItemListName.LastSeen;
    }

    const { productId } = product;

    const { insightsClient } = useInsightsClient();

    const { url, productTrackingData } = useProductTileData(product, intl, {
        isListView,
        indexName,
        queryId,
        trackingListName,
        hasLocationFilter,
        isModalView,
    });

    const productTrackingPayload = {
        product: productTrackingData,
        position,
        list: trackingListName,
        featureCategory: GA4FeatureCategory.ProductList,
        itemListName,
        itemListType: listType,
        isLocationSelected: hasLocationFilter,
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecProductImpression({
            ...productTrackingPayload,
            eventName: GA4EventName.ViewItemList,
        }),
    );

    const onProductTileClick = async (event?: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event?.preventDefault();
        window.location.href = enhanceUrlWithSearchParams(url);
        await window.yieldToMainThread();

        trackEecProductClick(productTrackingPayload);

        if (insightsClient && indexName && queryId) {
            sendAlgoliaObjectIdsClickedAfterSearchEvent({
                insightsClient,
                eventName: {
                    page: AlgoliaAnalyticsEventPage.ProductListingPage,
                    source:
                        trackingListName === TrackingListName.Bestseller
                            ? AlgoliaAnalyticsEventSource.Bestseller
                            : AlgoliaAnalyticsEventSource.ProductList,
                    action: AlgoliaAnalyticsEventAction.ProductClicked,
                },
                indexName,
                queryId,
                objectIds: [productId],
                positions: [position],
            });

            const algoliaAnalyticsStorage = getAlgoliaAnalyticsStorage();

            try {
                algoliaAnalyticsStorage.add(productId, AlgoliaAnalyticsDataSource.PRODUCT_LIST, {
                    indexName,
                    queryId,
                    timestamp: Date.now(),
                });
            } catch (error_) {
                const error = error_ as Error;
                if (isQuotaExceededError(error)) {
                    logToSentry(
                        `${error.message}; AlgoliaAnalytics entries: ${
                            Object.keys(algoliaAnalyticsStorage.get()).length
                        }`,
                    );
                }
            }
        }
    };

    return { setImpressionTrackedElement, onProductTileClick };
};

export { useOnProductTileClickAndImpression };
