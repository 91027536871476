import { FormattedMessage, useIntl } from 'react-intl';

import { Notification } from '@jsmdg/yoshi';
import { NotificationVariant } from '@jsmdg/yoshi/dist/enums/notification';
import { pageConfig, PageType } from '../../../shared/enums/pageType';
import { type ProductSelection } from '../../../shared/types/pageContent';
import { type AlgoliaProduct } from '../../../shared/types/searchResponse';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { WebsiteMarkup } from '../Markup/WebsiteMarkup';
import { messages } from './messages';
import styles from './Page.module.scss';

type PageHeaderProps = {
    readonly inPreview: boolean;
    readonly pageType: PageType;
    readonly indexId: string;
    readonly indexName: string;
    readonly productListContent?: ProductSelection;
    readonly headline?: string;
    readonly locationAware?: boolean;
};

const PageHeader = ({
    headline,
    inPreview,
    indexId,
    indexName,
    locationAware,
    pageType,
    productListContent,
}: PageHeaderProps): JSX.Element | null => {
    const intl = useIntl();
    const { getResults } = useAlgoliaContext();
    const results = getResults<AlgoliaProduct>(indexId, indexName);
    const isSearchPage = pageType === PageType.SearchPage;
    const searchPageHasResults = !!results?.nbHits;
    const showSearchTermResult = isSearchPage && searchPageHasResults;

    if (showSearchTermResult) return null;

    const locationName = productListContent?.initialSearch?.filter?.location?.name;
    const { isFullWidth, renderMarkup, showTitle } = pageConfig[pageType];

    return (
        <>
            {inPreview && (
                <Notification
                    variant={NotificationVariant.Warning}
                    message={intl.formatMessage(messages.previewPageMessage)}
                />
            )}
            {renderMarkup && <WebsiteMarkup />}
            {showTitle && (
                <h1 key="headline" className={isFullWidth ? `${styles.headline} text-center` : ''}>
                    {headline}
                    {locationAware && !!locationName && (
                        <>
                            &nbsp;
                            <FormattedMessage defaultMessage="in" />
                            &nbsp;
                            {locationName}
                        </>
                    )}
                </h1>
            )}
        </>
    );
};

export { PageHeader };
