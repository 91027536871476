import { useEffect, useRef } from 'react';
import { type InsightsClient } from 'search-insights';

const useInsightsClient = (): { insightsClient?: InsightsClient } => {
    const insightsClient = useRef<InsightsClient | undefined>(undefined);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,global-require
        insightsClient.current = require('search-insights');
    }, []);

    return {
        insightsClient: insightsClient.current,
    };
};

export { useInsightsClient };
