import classNames from 'classnames';

import { NavigationIcon, PureButton } from '@jsmdg/yoshi';
import { storageAvailable } from '../../../../helper/storageHelper';
import { SearchReducerActionType } from '../../../../reducers/searchReducer';
import styles from './CurrentLocationButton.module.scss';

type CurrentLocationButtonProps = {
    readonly onSubmit: (type: SearchReducerActionType) => void;
    readonly location?: string;
};

const CurrentLocationButton = ({
    location = '',
    onSubmit,
}: CurrentLocationButtonProps): JSX.Element | null => {
    const getCurrentLocation = (): void => {
        onSubmit(SearchReducerActionType.GeoCoordinates);
    };

    const shouldShowLocationButton =
        !location &&
        (typeof window === 'undefined' ||
            !storageAvailable('sessionStorage') ||
            window.sessionStorage.getItem('locationApiAccepted') !== 'false');

    if (!shouldShowLocationButton) {
        return null;
    }

    return (
        <PureButton
            data-testid="current-location-button"
            onClick={getCurrentLocation}
            className={classNames(
                'd-flex align-items-center justify-content-center',
                styles.button,
            )}
        >
            <NavigationIcon />
        </PureButton>
    );
};

export { CurrentLocationButton };
