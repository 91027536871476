/* eslint-disable complexity, @typescript-eslint/no-confusing-void-expression,  @typescript-eslint/no-misused-promises, canonical/filename-match-regex */
import { type ChangeEvent, useEffect, useRef, useState } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { Breakpoint, useBreakpoint } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Filter as FilterType } from '../../shared/types/search';
import { formatTrackingPriceRange } from '../helper/formatPrice';
import { getGridView } from '../helper/getGridView';
import { SearchReducerActionType } from '../reducers/searchReducer';
import { type Nullable } from '../types';
import { getActiveFilters } from './getActiveFilters';

const Step = 10;

type UsePriceFilterProps = {
    currencyCode: string;
    onSubmit: (
        label: SearchReducerActionType,
        value: { min: Nullable<number>; max: Nullable<number> } | Nullable<number>,
    ) => void;
    shouldReset?: boolean;
    options: {
        min: number;
        max: number;
    };
    values: Array<Nullable<number>>;
    isModalView?: boolean;
    filter: FilterType;
    listType?: GA4FilterListType;
};

type UsePriceFilterReturn = {
    sliderDidUpdate: boolean;
    priceData: {
        price: Array<Nullable<number>>;
        isDefaultMin: boolean;
        isDefaultMax: boolean;
    };
    rangeValues: number[];
    inputsValues: Array<number | string>;
    onRangeChange: (values: number[], isChange?: boolean) => void;
    onPriceChange: (values: number[], explicitReset?: boolean) => void;
    onInputUpdate: (event: ChangeEvent) => void;
    handleReset: () => void;
    handleOnClickOutside: () => void;
};

const usePriceFilterPLP = ({
    currencyCode,
    onSubmit,
    options,
    shouldReset,
    values,
    isModalView,
    filter,
    listType,
}: UsePriceFilterProps): UsePriceFilterReturn => {
    const { locale, settingCookie } = useFragmentContext<FragmentContext>();
    const [price, setPrice] = useState(values);
    const [internalValue, setInternalValue] = useState(values);
    const [sliderDidUpdate, setSliderDidUpdate] = useState(false);
    const [isInputUpdate, setIsInputUpdate] = useState(false);
    const [inputsValues, setInputsValues] = useState([
        values[0] || options.min,
        values[1] || `${options.max}+`,
    ]);
    const gridView = getGridView(settingCookie);

    useEffect(() => {
        if (shouldReset) {
            setPrice([null, null]);
            setInternalValue([null, null]);
        }
    }, [shouldReset]);

    const isDesktop = useBreakpoint(Breakpoint.SM);

    const previousPriceSliderRef = useRef([price[0] || options.min, price[1] || options.max]);

    const isDefaultMax = (maxValue: Nullable<number>): boolean => {
        return maxValue === null || maxValue >= options.max;
    };

    const isDefaultMin = (minValue: Nullable<number>): boolean => {
        return minValue === null || minValue === options.min || minValue === 0;
    };

    const isDefaultValues = (valueArray: Array<Nullable<number>>): boolean => {
        return isDefaultMin(valueArray[0]) && isDefaultMax(valueArray[1]);
    };

    const trackPriceFilterInteraction = (min: Nullable<number>, max: Nullable<number>): void => {
        const minTrackingValue = isDefaultMin(min) ? 0 : min;
        const maxTrackingValue = isDefaultMax(max) ? null : max;
        const priceRangeLabel = formatTrackingPriceRange({
            locale,
            currencyCode,
            min: minTrackingValue,
            max: maxTrackingValue,
        });
        const activeFilters = getActiveFilters(filter, ['Price']);

        trackFilterInteraction(
            'SetPrice',
            priceRangeLabel,
            {
                eventName: GA4EventName.SetFilter,
                filter_type: isInputUpdate ? 'Price via type-in' : 'Price via slider',
                filter_value: priceRangeLabel,
                list_type: listType ?? GA4FilterListType[gridView],
            },
            activeFilters,
        );
    };

    const createActionTask =
        (
            label: SearchReducerActionType,
            value:
                | {
                      min: Nullable<number>;
                      max: Nullable<number>;
                  }
                | Nullable<number>,
        ) =>
        () =>
            onSubmit(label, value);
    const setPriceTask = (value: React.SetStateAction<Array<Nullable<number>>>) => () =>
        setPrice(value);

    const onPriceChange = async (
        sliderValues: number[],
        shouldIgnoreTracking = false,
    ): Promise<void> => {
        const [sliderMin, sliderMax] = sliderValues;
        const [previousMin, previousMax] = previousPriceSliderRef.current;
        const [priceMin, priceMax] = price;

        const tasks = [];

        if (isDefaultValues(sliderValues)) {
            // if default values are set, no pricefilter should be used
            tasks.push(
                setPriceTask([null, null]),
                createActionTask(SearchReducerActionType.Price, {
                    min: null,
                    max: null,
                }),
            );
        } else if (
            sliderMin !== previousMin &&
            sliderMax !== previousMax &&
            isDefaultMax(sliderMax)
        ) {
            // both values changed & price max is set to default
            tasks.push(
                setPriceTask(sliderValues),
                createActionTask(SearchReducerActionType.Price, {
                    min: sliderMin,
                    max: null,
                }),
            );
        } else if (
            sliderMin !== previousMin &&
            sliderMax !== previousMax &&
            isDefaultMin(sliderMin)
        ) {
            // both values changed & price min is set to default
            tasks.push(
                setPriceTask(sliderValues),
                createActionTask(SearchReducerActionType.Price, {
                    min: null,
                    max: sliderMax,
                }),
            );
        } else if (sliderMin !== previousMin && sliderMax !== previousMax) {
            // both values changed & both are different from default
            tasks.push(
                setPriceTask(sliderValues),
                createActionTask(SearchReducerActionType.Price, {
                    min: sliderMin,
                    max: sliderMax,
                }),
            );
        } else if (sliderMin !== previousMin && !isDefaultMin(sliderMin)) {
            // only price min changed & it is different from default
            tasks.push(
                setPriceTask([sliderMin, priceMax]),
                createActionTask(SearchReducerActionType.PriceMin, sliderMin),
            );
        } else if (sliderMax !== previousMax && !isDefaultMax(sliderMax)) {
            // only price max changed and it is different from default
            tasks.push(
                setPriceTask([priceMin, sliderMax]),
                createActionTask(SearchReducerActionType.PriceMax, sliderMax),
            );
        } else if (isDefaultMin(sliderMin)) {
            // min changed to default value
            tasks.push(
                setPriceTask([null, priceMax]),
                createActionTask(SearchReducerActionType.PriceMin, null),
            );
        } else if (isDefaultMax(sliderMax)) {
            // max changed to default value
            tasks.push(
                setPriceTask([priceMin, null]),
                createActionTask(SearchReducerActionType.PriceMax, null),
            );
        } else {
            // nothing changed
            tasks.push(
                setPrice(price),
                onSubmit(SearchReducerActionType.Price, {
                    min: priceMin,
                    max: priceMax,
                }),
            );
        }

        // it should only be tracked if user did not click reset but changed values manually
        if (!shouldIgnoreTracking) {
            tasks.push(trackPriceFilterInteraction(sliderMin, sliderMax));
        }

        tasks.push(setSliderDidUpdate(false));

        let deadline = performance.now();

        while (tasks.length > 0) {
            if (navigator.scheduling?.isInputPending() || performance.now() >= deadline) {
                // eslint-disable-next-line no-await-in-loop
                await window.yieldToMainThread();
                deadline = performance.now() + 10;

                // eslint-disable-next-line no-continue
                continue;
            }

            const task = tasks.shift();
            if (typeof task === 'function') {
                task();
            }
        }

        previousPriceSliderRef.current = sliderValues;
    };

    const onRangeChange = async (
        sliderValues: number[],
        shouldChange = false,
        isInputEvent?: boolean,
    ): Promise<void> => {
        const [min, max] = sliderValues;
        const shouldUpdateMax = shouldChange && max && max >= options.max;

        setInputsValues([min || 0, shouldUpdateMax ? `${options.max}+` : max || 0]);

        setInternalValue([min, !shouldUpdateMax ? max : null]);

        setSliderDidUpdate(true);
        setIsInputUpdate(!!isInputEvent);

        if ((!isDesktop || isModalView) && !isInputEvent) await onPriceChange(sliderValues);
    };

    const onInputUpdate = async (event: ChangeEvent): Promise<void> => {
        const isMinInput = (event.target as HTMLInputElement).name === 'minValue';
        const isBlurEvent = event.type === 'blur';
        const min = Number(inputsValues[0]);
        const max = Number(inputsValues[1]) || options.max;
        let value = Number((event.target as HTMLInputElement).value);

        if (isBlurEvent) {
            if (isMinInput) {
                value = value < max - Step ? value : max - Step;
            } else {
                value = value > min + Step ? value : min + Step || max;
            }
        }

        const rangeValues = isMinInput ? [value, max] : [min, value];
        const compareMin = price[0] === null ? options.min : price[0];
        const compareMax = price[1] === null ? options.max : price[1];

        const shouldNotTrackFilterChange =
            rangeValues[0] === compareMin && rangeValues[1] === compareMax && isBlurEvent;

        await onRangeChange(rangeValues, isBlurEvent || isMinInput, true);

        if (!isDesktop || isModalView) {
            await onPriceChange(rangeValues, shouldNotTrackFilterChange);
        }
    };

    const handleReset = async (): Promise<void> => {
        await window.yieldToMainThread();
        await onPriceChange([0, 500], true);
        setInternalValue([options.min, options.max]);
        setInputsValues([options.min, `${options.max}+`]);

        // price just got removed, so we should not track it in active filters
        const { ...remainingFilters } = filter;
        const activeFilters = getActiveFilters(remainingFilters);

        trackFilterInteraction(
            'SetPrice',
            'ResetPrice',
            {
                eventName: GA4EventName.ResetFilter,
                filter_type: 'Price',
                list_type: listType ?? GA4FilterListType[gridView],
            },
            activeFilters,
        );
    };

    const handleOnClickOutside = async (): Promise<void> => {
        let min = internalValue[0] || options.min;
        let max = internalValue[1] || options.max;

        const [prevMin, prevMax] = price;

        if (min !== prevMin) {
            min = min < max - Step ? min : max - Step;
        }

        if (max !== prevMax) {
            max = max > min + Step ? max : min + Step || max;
        }

        if (sliderDidUpdate) {
            await onRangeChange([min, max], true);
            await onPriceChange([min, max]);
            setSliderDidUpdate(false);
        }
    };

    return {
        priceData: {
            price,
            isDefaultMin: isDefaultMin(price[0]),
            isDefaultMax: isDefaultMax(price[1]),
        },
        rangeValues: [internalValue[0] || options.min, internalValue[1] || options.max],
        inputsValues,
        sliderDidUpdate,
        onPriceChange,
        onRangeChange,
        onInputUpdate,
        handleReset,
        handleOnClickOutside,
    };
};

export { usePriceFilterPLP };
