import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { type GA4FilterListType } from '@jsmdg/tracking';
import {
    CloseIcon,
    Input,
    LocationFilter as YoshiLocationFilter,
    MapMarkerIcon,
    PureButton,
    ValidationStates,
} from '@jsmdg/yoshi';
import { type Filter as FilterType } from '../../../../shared/types/search';
import { useLocationFilter } from '../../../hooks';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { SuggestionList } from '../../SuggestionList';
import { DistanceFilter } from '../Distance';
import { CurrentLocationButton } from './CurrentLocationButton';
import styles from './LocationFilter.module.scss';

type LocationFilterProps = {
    readonly locationName?: string;
    readonly hasResult?: boolean;
    readonly shouldReset?: boolean;
    readonly isOuter?: boolean;
    readonly withDataTestId?: boolean;
    readonly geoLocationError?: GeolocationPositionError;
    readonly onSubmit: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly filter?: FilterType;
    readonly isModalView?: boolean;
    readonly className?: string;
    readonly listType?: GA4FilterListType;
    readonly isDesktop?: boolean;
};

const LocationFilter = ({
    className,
    filter,
    geoLocationError,
    hasResult = true,
    isDesktop,
    isModalView,
    isOuter = false,
    listType,
    locationName,
    onSubmit,
    shouldReset = false,
    withDataTestId = true,
}: LocationFilterProps): JSX.Element => {
    const {
        errorMessage,
        filterRef,
        formatMessage,
        handleKeyDown,
        isActive,
        location,
        locationSuggestions,
        messages,
        onClearLocation,
        onLocationChange,
        onSuggestionClick,
        selected,
        setIsActive,
        setSelected,
    } = useLocationFilter({
        locationName,
        onSubmit,
        shouldReset,
        err: geoLocationError,
        filter,
        listType,
    });

    const distanceFilter = filter && filter.location?.name && (
        <DistanceFilter
            distanceValue={filter.location?.distance}
            onSubmit={onSubmit}
            show
            filter={filter}
            listType={listType}
        />
    );

    const selectedValue = {
        id: selected,
        title: location,
        highlighted: true,
    };

    if (!isModalView && isDesktop) {
        return (
            <YoshiLocationFilter
                selectedValue={selectedValue}
                suggestionData={locationSuggestions ?? []}
                onSelect={item =>
                    onSuggestionClick(item.placeId, `${item.title ?? ''}, ${item.subTitle ?? ''}`)
                }
                onClickCurrentLocationButton={() =>
                    onSubmit(SearchReducerActionType.GeoCoordinates)
                }
                a11yCurrentLocationButton={formatMessage(messages.currentLocation)}
                showCurrentLocationButton={!geoLocationError}
                rangeComponent={distanceFilter}
                onInputChange={onLocationChange}
                onInputClear={onClearLocation}
                helperText={errorMessage}
                tooltipText={formatMessage(messages.currentLocation)}
                placeholder={
                    location
                        ? formatMessage(messages.placeholderShort)
                        : formatMessage(messages.placeholderLong)
                }
            />
        );
    }

    return (
        <div
            className={classNames(
                styles.locationNoOutline,
                'position-relative align-items-center',
                {
                    [styles.outerFilter]: isOuter || isModalView,
                    [styles.modalView]: isModalView,
                },
                className,
            )}
            data-testid={withDataTestId ? 'location-filter' : undefined}
        >
            {(!isOuter || isModalView) && (
                <div
                    className={classNames(
                        styles.locationLabel,
                        'fw-semibold mb-1x mb-sm-0 mr-sm-3x',
                        {
                            [styles.modalLocationLabel]: isModalView,
                            'd-block d-sm-none': isModalView,
                        },
                    )}
                >
                    <FormattedMessage defaultMessage="Standort" />
                </div>
            )}

            <form
                // autoComplete with "off" in the form tag is for firefox to stop it from autofill the inputs
                // the input contains another autoComplete attribute for chrome with an other value (see yoshi)
                autoComplete="off"
                className={styles.form}
                onSubmit={() => undefined}
                ref={filterRef}
            >
                <Input
                    className={classNames('w-100', styles.inputWrapper, {
                        [styles.notFulllWidth]: !!location,
                    })}
                    inputClasses={classNames(styles.locationInput, 'pl-5x pr-6x pr-sm-4x')}
                    helperText={errorMessage}
                    helperTextClasses={styles.errorMessage}
                    hideLabel
                    name="locationSearch"
                    onClick={() => setIsActive(!isActive)}
                    onInput={onLocationChange}
                    onKeyDown={handleKeyDown}
                    placeholder={
                        location
                            ? formatMessage(messages.placeholderShort)
                            : formatMessage(messages.placeholderLong)
                    }
                    showHelperText={errorMessage.length > 0}
                    validationState={errorMessage ? ValidationStates.Error : undefined}
                    value={location || ''}
                    leftComponent={
                        <MapMarkerIcon
                            className={classNames(styles.markerIcon, {
                                [styles.markerIconActive]: !!location,
                            })}
                            size={26}
                        />
                    }
                    rightComponent={
                        <>
                            {location && (
                                <PureButton
                                    aria-label="clear location"
                                    className={styles.closeIconWrapper}
                                    onClick={onClearLocation}
                                >
                                    <CloseIcon className={styles.closeIcon} />
                                </PureButton>
                            )}
                        </>
                    }
                />

                {!geoLocationError && (
                    <CurrentLocationButton
                        geoLocationError={geoLocationError}
                        hasResult={hasResult}
                        location={location}
                        onSubmit={onSubmit}
                        isActive={isActive}
                        onCloseCurrentLocation={() => setIsActive(!isActive)}
                    />
                )}

                {locationSuggestions && isActive && (
                    <SuggestionList
                        selected={selected}
                        setSelected={setSelected}
                        suggestions={locationSuggestions}
                        suggestionClick={onSuggestionClick}
                    />
                )}
            </form>
        </div>
    );
};

export { LocationFilter };
