import { type SearchResults } from 'algoliasearch-helper';

import { mapAlgoliaProductHitToProduct } from '../../shared/helpers/mapAlgoliaProductHitToProduct';
import { type Pagination } from '../../shared/types/search';
import { type AlgoliaProduct, type Product } from '../../shared/types/searchResponse';
import { getAdjustedItemCountByLimit } from '../helper/getAdjustedItemCountByLimit';

type ProductsState = {
    products: Product[];
    pageSize: number;
    nbItems: number;
    nbPages: number;
    hasNoResults: boolean;
    limit?: number;
};

const useAlgoliaProducts = (
    searchResult?: SearchResults<AlgoliaProduct>,
    pagination?: Pagination,
    showLocation?: boolean,
): ProductsState => {
    const products =
        searchResult?.hits.map(algoliaProductHit =>
            mapAlgoliaProductHitToProduct(algoliaProductHit, showLocation),
        ) ?? [];
    const pageSize = searchResult?.hitsPerPage || pagination?.size || 48;
    const nbItems = getAdjustedItemCountByLimit(searchResult?.nbHits, pagination?.limit);

    return {
        products,
        pageSize,
        nbItems,
        nbPages: searchResult?.nbPages || (pageSize ? Math.ceil(nbItems / pageSize) : 0),
        // The `__isArtificial` flag makes sure to not display the No Results message
        // when no hits have been returned yet.
        // eslint-disable-next-line no-underscore-dangle
        hasNoResults: !searchResult?.__isArtificial && searchResult?.nbHits === 0,
        limit: pagination?.limit || 0,
    };
};

export { useAlgoliaProducts };
